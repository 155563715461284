//代币列表
export const BNBlist = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },
    {
      "chainId": 56,
      "address": "0x024a9ad0ecfaa8b3566d3310affb358379a55e7a",
      "name": "RAC",
      "symbol": "RAC",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/RAC.png')
    },
    {
      "chainId": 56,
      "address": "0xfa7e034dc83a50b67b4a0b421eb6a926a13b685f",
      "name": "Hamster Run Game",
      "symbol": "Hamster",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/Hamster.png')
    },
  
]

//代币列表
export const BNBlistV2 = [
  {
      "chainId": 56,
      "address": "0x55d398326f99059fF775485246999027B3197955",
      "name": "USDT",
      "symbol": "USDT",
      "decimals": 18,
      "logoURI": require('@/assets/img/logo/USDT.png')
    },

    {
      "chainId": 56,
      "address": "0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c",
      "name": "BTCB",
      "symbol": "BTCB",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/BTCB.png')
    },
    {
      "chainId": 56,
      "address": "0x2170ed0880ac9a755fd29b2688956bd959f933f8",
      "name": "ETH",
      "symbol": "ETH",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/ETH.png')
    },
    {
      "chainId": 56,
      "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
      "name": "CAKE",
      "symbol": "CAKE",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/CAKE.png')
    },
    {
      "chainId": 56,
      "address": "0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe",
      "name": "XRP",
      "symbol": "XRP",
      "decimals": 18,
      "logoURI": require('@/assets/tokenlogo/XRP.png')
    },
]



