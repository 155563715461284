import configURL from '@/config/configUrl/configUrl.js';
export const viewHash = hash => {
  window.open('https://bscscan.com/tx/' + hash);
};

export const viewToken = address => {
  window.open('https://bscscan.com/token/' + address);
};

export  const getFullImagePath = (imagePath) => {
  const apiPrefix = configURL.http_url; // 替换为你的接口前缀
  // 判断路径是否以 'http' 或 'https' 开头
  if (imagePath.startsWith('http://') || imagePath.startsWith('https://')) {
      return imagePath; // 如果是完整路径，则直接返回
  }

  // 如果不是完整路径，拼接接口前缀并返回
  return apiPrefix + imagePath;
}