<template>
    <div class="index" >
      <!-- <Header></Header> -->
      <div class="recruit_title">
      <div style="transform: rotate(90deg)" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
      <div class="functional_region">
        <div class="region">
          <div v-for="(item,index) of functionOption"
               :key="index"
               @click="switchovers(index)"
               :class="index ==regionIndex?'special':''">
            <span>{{$t(item.name)}}</span>
          </div>
        </div>
      </div>
  
      <div class="box">
        <router-view />
      </div>
    </div>
  </template>
  
  <script>

import topbutton from '@/components/topbutton.vue';
  export default {
    data () {
      return {
        // 功能列表
        functionOption:  [{ name: 'lang.d1' }, { name: 'lang.d2' },{ name: 'lang.d3' }],
        regionIndex: '0',
      };
    },
    components: { topbutton },
    created () {
        if (this.$route.query.id ) {
        this.regionIndex = this.$route.query.id;
        console.log(this.regionIndex);
       
      
      }
     
    },
    mounted () {
       
    },
    methods: {
      switchovers (index) {
        this.regionIndex = index;
        console.log(index);
        if (this.regionIndex == '0') {
          this.$router.push({ name: 'ChoBi1' });
        } else if(this.regionIndex == '1'){
          this.$router.push({ name: 'TiBi2' });
        }else{
            this.$router.push({ name: 'ZhuangZhan3' });
        }
      },
  
      getBasicList () {
  
      
      },
      toprouter(){
        this.$router.push({ name: 'Index' });
      }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .recruit_title {
  color: var( --text-color);
  padding: 0 20px;
 
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  .index {
   padding-top: 20px;
        display: flex;
    flex-direction: column;
    background: var(--bg-color);
    
    width: 100%;
  }
  // .box {
  //   flex: 1;
  // }
  .functional_region {
    padding: 20px 20px 0;
  // margin-top: 50px;
    .region {
      padding: 4px;
      display: flex;
      width: 100%;
      line-height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      color: var( --text-color);
      font-weight: 600;
    border: 1px solid #66B4AE;
      text-align: center;
      border-radius: 34px;
      // margin: 20px 0;
      margin-bottom: 20px;
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 10px 0;
      }
      .special {
        // width: 50%;
        color: rgba(255, 255, 255, 1);
        background: rgba(102, 180, 174, 1);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }
  }
  /deep/ .router {
    background: #000 !important;
  }
  </style>