<template>
    <div class="navbar">
        <div class="nav_box" v-for="(item, index) in NarData" :key="index" @click="bNav(item, index)">
            <div class="nav_box_icon">
                <img :src="$route.name == item.router ? item.active_icon : item.icon" alt="">
            </div>
            <span :style="$route.name == item.router ? 'color: rgba(102, 180, 174, 1);' : ''">{{ $t(item.name) }}</span>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            NarDataIndex: 0,
            NarData: [
                {
                    name: 'lang.矿卡',
                    router: 'NFTCenter',
                    icon: require('@/assets/img/icon/kuangchi_icon2.png'),
                    active_icon: require('@/assets/img/icon/kuangchi_icon1.png'),
                },

                {
                    name: 'lang.铸币池',
                    router: 'MintPool',
                    icon: require('@/assets/img/icon/MintPool_icon2.png'),
                    active_icon: require('@/assets/img/icon/MintPool_icon1.png'),
                },
                {
                    name: 'lang.社区',
                    router: 'engbill',
                    icon: require('@/assets/img/icon/user_icon2.png'),
                    active_icon: require('@/assets/img/icon/user_icon1.png'),
                }
            ]
        }
    },
    components: {

    },
    mounted() {

    },
    methods: {
        bNav(Data, index) {
            if (Data.router == 'c2c') return this.$toast(this.$t('lang.暂未开放'));
            if (Data.router != '') {
                this.$router.push({ name: Data.router });
            } else {
            }
        },
    },
}

</script>
<style scoped lang='less'>
.navbar {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 1);
    box-shadow: -4px 0px 8px rgba(0, 0, 0, 0.12);

    .nav_box {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 12px;

        .nav_box_icon {
            width: 24px;
            height: 24px;
            margin-bottom: 5px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>