<template>
  <div class="remove">
    <div class="remove_top">
      <div class="head">
        <div @click="liquidity" class="function_button">
          <div>
            <img class="left_arrow" src="@/assets/img/left_arrow.png" v-if="ColorCheckData == 'black'" />
            <img class="left_arrow" src="@/assets/img/left_arrow1.png" v-else />
          </div>
          <div class="liquidity_title">
            <span class="title">
              {{ $t("lang.swap104") }}{{ removeData.fromSymbol }}-{{
                removeData.toSymbol
              }}{{ $t("lang.swap78") }}
            </span>
            <div>
              <span>
                {{ $t("lang.swap111") }} {{ removeData.fromSymbol }} {{ $t("lang.swap112") }} {{
                  removeData.toSymbol
                }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="content">
        <div class="slider">
          <div class="amount">
            <span>{{ $t("lang.swap113") }}</span>
            <span style=" color: #999999;">{{ $t("lang.swap273") }}</span>
          </div>
          <div class="tips">
            <span>{{ sliderValue }}%</span>
          </div>
          <van-slider inactive-color="#949494" active-color="#356EF1" bar-height="4" button-size="14"
            v-model="sliderValue" @change="changeValue" />
          <div class="slider_list">
            <span v-for="(item, index) of slider_list" :key="index" :style="{ 'background': item.bg }"
              @click="percentage(item.ratio)">{{ item.ratio }}{{ item.ratio == "MAX" ? "" : "%" }}</span>
          </div>
        </div>
        <img src="@/assets/img/exchange.png" class="iconDown" />

        <div class="exchange">
          <!-- 你将获得 -->
          <div class="will_get">
            <span class="headline">{{ $t("lang.swap114") }}</span>
            <div class="detailed_data">
              <div class="detailed">
                <div class="detailed_left">
                  <img class="image" :src="removeData.fromLogoURI" />
                  <span>{{ $t("lang.swap103") }}: {{ removeData.fromSymbol }}</span>
                </div>
                <div>
                  <span>{{ significantDigits(removeData.fromBalance * 1 * (sliderValue / 100)) }}</span>
                </div>
              </div>

              <div class="detailed">
                <div class="detailed_left">
                  <img class="image" :src="removeData.toLogoURI" />
                  <span>{{ $t("lang.swap103") }}: {{ removeData.toSymbol }}</span>
                </div>
                <div>
                  <span>{{ significantDigits((removeData.toBalance * 1) * (sliderValue / 100)) }}</span>
                </div>
              </div>

              <!-- <div class="receive">
              <span>接收WBNB</span>
            </div>-->
            </div>
          </div>

          <!-- 价格 -->
          <div class="will_get">
            <span class="headline">{{ $t("lang.swap7") }}</span>
            <div class="detailed_data">
              <div class="detailed">
                <span>1 {{ removeData.fromSymbol }}=</span>
                <span>
                  {{ significantDigits(removeData.toBalance / removeData.fromBalance) }}
                  {{ removeData.toSymbol }}
                </span>
              </div>
              <div class="detailed">
                <span>1 {{ removeData.toSymbol }}=</span>
                <span>
                  {{ significantDigits(removeData.fromBalance / removeData.toBalance) }}
                  {{ removeData.fromSymbol }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="button">
          <van-button :type="authorizationShow ? 'primary' : 'default'" v-if="authorizationShow" class="nothing_swapBtn"
            :loading="approveLoading" loading-text="Approving..." :disabled="!authorizationShow"
            @click="handleApprove">{{ $t("lang.swap14") }}</van-button>
          <van-button :type="authorizationShow ? 'default' : 'primary'" class="nothing_swapBtn" :loading="removeLoading"
            loading-text="Waiting..." @click="confirmRemoval">{{ $t("lang.swap104") }}</van-button>
        </div>
      </div>
    </div>

    <div class="remove_button">
      <div class="title">
        <span>{{ $t("lang.swap110") }}</span>
      </div>
      <div class="detailed">
        <div class="quantity">
          <img class="img" :src="removeData.fromLogoURI" />
          <img class="img" :src="removeData.toLogoURI" />
          <span>{{ removeData.fromSymbol }}</span>
          /
          <span>{{ removeData.toSymbol }}</span>
        </div>
        <div class="quantity_right">
          <span>{{ significantDigits(removeData.lpBalanceShow) }}</span>
        </div>
      </div>
      <div class="detailed">
        <div class="quantity">
          <span style="opacity: 0.6;">{{ $t("lang.swap96") }}：</span>
        </div>
        <div class="quantity_right">
          <span>{{ significantDigits(removeData.percentage * 1) }}%</span>
        </div>
      </div>
      <div class="detailed">
        <div class="quantity">
          <span style="opacity: 0.6;">{{ $t("lang.swap103") }} {{ removeData.fromSymbol }}:</span>
        </div>
        <div class="quantity_right">
          <span>{{ significantDigits(removeData.fromBalance) }}</span>
        </div>
      </div>
      <div class="detailed">
        <div class="quantity">
          <span style="opacity: 0.6;">{{ $t("lang.swap103") }} {{ removeData.toSymbol }}:</span>
        </div>
        <div class="quantity_right">
          <span>{{ significantDigits(removeData.toBalance) }}</span>
        </div>
      </div>
    </div>
    <van-popup v-model="addliquidityDetailShow" @closed="handleSwapDetailClosed" position="bottom" round>
      <AddliquidityDetail @listenClose="handleSwapDetailClose" @listenConfirm="handleRemoveLiquidity"
        :removeDataCopy="removeDataCopy" :addliquidityDetail="1" />
    </van-popup>

    <van-popup v-model="pendingDetailShow" round position="bottom" @closed="handlePendingSwapClosed">
      <PendingSwap @listenClose="handlePendingSwapClose" :pendingDetail="pendingDetail"
        :removeDataCopy="removeDataCopy" />
    </van-popup>
  </div>
</template>

<script>
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import AddliquidityDetail from './addliquidityDetail.vue';
import { mapState } from 'vuex';
import { getJackRouterAddress } from '@/utils/addressHelp.js';
import { splitSignature } from '@ethersproject/bytes';
import web3 from 'web3';
import { useJackRouterContract, useErc20Contract } from '@/utils/useContract';
import { getErc20Contract, getJackPairContract } from '@/utils/contractHelp';
import { ETH, BNB } from '@/config/constants/bnb';
import { MaxUint256 } from '@ethersproject/constants';
import PendingSwap from '@/views/swap/PendingSwap.vue';

import { homedata } from '@/api/index'
export default {
  data() {
    return {
      sliderValue: 0, //份额
      slider_list: [{ ratio: 25, bg: 'rgba(0, 186, 173, 1)' }, { ratio: 50, bg: 'rgba(53, 110, 241, 1)' }, { ratio: 75, bg: 'rgba(137, 174, 254, 1)' }, { ratio: 'MAX', bg: 'rgba(131, 102, 188, 1)' }],
      removeData: {},
      removeDataCopy: {},
      addliquidityDetailShow: false,
      approveLoading: false, //启用loading
      removeLoading: false,
      r: '',
      v: '',
      s: '',
      percentageMin: '<0.01%',
      percentageMax: '>100%',
      authorizationShow: true,
      pendingDetailShow: false, //等待交换弹窗
      pendingDetail: { status: 3, hash: '', fromInput: '', toInput: '' },
      isShowToast: false,
      isDel: true,
      imgurl: ''
    };
  },
  mounted() {
    // this.handleApproveBtnShow(this.removeData.lpAddress);
  },
  watch: {
    account() {
      //获取账号
      this.handleApproveBtnShow(this.removeData.lpAddress);
      this.getLpBalance(this.removeData);
    },
  },
  async created() {
    homedata.gettokenimgurl().then(res => {
      this.imgurl = res.data.image_domain

    })

    this.removeData = JSON.parse(this.$route.query.item);
    this.handleApproveBtnShow(this.removeData.lpAddress);
    this.handleTimerLpBalance();
  },
  beforeDestroy() {
    //清除定时器
    window.clearInterval(this.timerLpBalance);
  },
  methods: {
    async handleTimerLpBalance() {
      window.clearTimeout(this.timerBalance);
      this.timerBalance = setInterval(async () => {
        this.getLpBalance(this.removeData);
      }, 3000);
    },
    async getLpBalance(item) {
      if (this.account) {
        const jackPairContract = getJackPairContract(item.lpAddress);
        this.removeData.lpBalance = await jackPairContract.methods.balanceOf(this.account).call();
        let reserves = await jackPairContract.methods.getReserves().call(); //获取当前总额
        let allBalance = await jackPairContract.methods.totalSupply().call();
        let scale = accDiv(this.removeData.lpBalance, allBalance) * 1; //当前比例
        if (item.fromAddress.toLowerCase() < item.toAddress.toLocaleLowerCase()) {
          this.removeData.fromBalance = (reserves._reserve0 * scale) / Math.pow(10, this.removeData.fromDecimals);
          this.removeData.toBalance = (reserves._reserve1 * scale) / Math.pow(10, this.removeData.toDecimals);
        } else {
          this.removeData.fromBalance = (reserves._reserve1 * scale) / Math.pow(10, this.removeData.fromDecimals);
          this.removeData.toBalance = (reserves._reserve0 * scale) / Math.pow(10, this.removeData.fromDecimals);
        }
      }
    },
    async handleEnable() {
      this.approveLoading = true;
      const to = getJackRouterAddress();
      const networkId = this.net;
      let chain_id = '0x' + this.net.toString(16);
      let lpBalance = this.removeData.lpBalance * (this.sliderValue / 100);
      const deadline = accAdd(accDiv(Date.parse(new Date()), 1000), accMul(this.deadline, 60));
      const domain = {
        name: 'Swap LPs',
        version: '1',
        chainId: networkId + '',
        verifyingContract: this.removeData.lpAddress + '',
      };

      const EIP712Domain = [
        { name: 'name', type: 'string' },
        { name: 'version', type: 'string' },
        { name: 'chainId', type: 'uint256' },
        { name: 'verifyingContract', type: 'address' },
      ];

      const Permit = [
        { name: 'owner', type: 'address' },
        { name: 'spender', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
        { name: 'deadline', type: 'uint256' },
      ];

      const message = {
        owner: this.account,
        spender: to,
        value: lpBalance + '',
        nonce: '0x00',
        deadline: deadline + '',
      };

      const data = JSON.stringify({
        types: {
          EIP712Domain,
          Permit,
        },
        domain,
        primaryType: 'Permit',
        message,
      });
      try {
        const from = this.account;
        ethereum
          .request({
            method: 'eth_signTypedData_v4',
            params: [from, data],
          })
          .then(splitSignature)
          .then(signature => {
            this.v = signature.v;
            this.r = signature.r;
            this.s = signature.s;
            this.approveLoading = false;
          });
        // console.log(sign, "返回的签名");
        // this.r = "0x" + sign.substring(2).substring(0, 64);
        // this.s = "0x" + sign.substring(2).substring(64, 128);
        // this.v = "0x" + sign.substring(2).substring(128, 130);
      } catch (err) {
        console.error(err);
      }
    },
    // 是否需要显示授权按钮
    async handleApproveBtnShow(address) {
      console.log('address', address);
      if (!address) {
        return;
      }
      const to = getJackRouterAddress();

      let erc20ContractTo = getErc20Contract(address);
      this.allowance = await erc20ContractTo.methods.allowance(this.account, to).call();
      console.log('this.allowance', this.allowance);
      if (this.allowance > 0) {
        this.authorizationShow = false;
      }
    },

    //授权lp给router使用
    async handleApprove() {
      const erc20Contract = useErc20Contract(this.removeData.lpAddress);
      const amount = MaxUint256.toString();
      const to = getJackRouterAddress();
      const gas = await erc20Contract.methods.approve(to, amount).estimateGas({ from: this.account });
      this.approveLoading = true;
      erc20Contract.methods
        .approve(to, amount)
        .send({
          from: this.account,
          gasPrice: web3.utils.numberToHex(this.gasPrice),
        })
        .on('transactionHash', hash => { })
        .on('receipt', receipt => { })
        .on('confirmation', receipt => {
          this.approveLoading = false;
          this.authorizationShow = false;
        })
        .on('error', (error, receipt) => {
          this.approveLoading = false;
          throw error;
        });
    },
    liquidity() {
      this.$router.push({ name: 'LiquidityIndex', query: { id: 1 } });
    },
    changeValue(value) {
    },
    percentage(item) {
      if (item == 'MAX') {
        this.sliderValue = 100;
      } else {
        this.sliderValue = item;
      }
    },
    isBnB(token) {
      if (token.toLowerCase() === BNB.address.toLowerCase()) {
        return true;
      }
      return false;
    },
    //移除
    confirmRemoval() {
      console.log(this.authorizationShow);
      if (this.sliderValue == 0) {
        this.$toast(this.$t('lang.swap10'));
        // uni.showToast({
        //   title: this.$t('lang.swap10'),
        //   icon: 'none',
        // });
        return;
      }
      if (!this.authorizationShow) {
        this.removeDataCopy = { ...this.removeData };
        this.removeDataCopy.fromRecent = this.removeData.fromBalance * (this.sliderValue / 100);
        this.removeDataCopy.toRecent = this.removeData.toBalance * (this.sliderValue / 100);
        this.removeDataCopy.lpRecent = (this.removeData.lpBalance * 1 * (this.sliderValue / 100)) / Math.pow(10, 18);
        this.addliquidityDetailShow = true;
      }
    },
    handlePendingSwapClose() {
      this.pendingDetailShow = false;
    },
    handlePendingSwapClosed() {
      this.pendingDetail = { status: 3, hash: '', fromInput: '', toInput: '' };
    },
    handleSwapDetailClose() {
      this.addliquidityDetailShow = false;
    },

    async handleRemoveLiquidity() {
      this.addliquidityDetailShow = false;
      this.pendingDetailShow = true;
      this.isSave = true;
      const slippage = accDiv(this.slippage, 100);
      const deadline = accAdd(accDiv(Date.parse(new Date()), 1000), accMul(this.deadline, 60));
      const amountIn = this.removeDataCopy.fromRecent * 1 * Math.pow(10, this.removeDataCopy.fromDecimals);
      const amountMin = toFixed(accSub(amountIn, accMul(amountIn, slippage)), 0);
      const amountOut = this.removeDataCopy.toRecent * 1 * Math.pow(10, this.removeDataCopy.toDecimals);

      const amountOutMin = toFixed(accSub(amountOut, accMul(amountOut, slippage)), 0);
      let lpBalance = '';
      if (this.sliderValue != 100) {
        lpBalance = significantDigits(this.removeDataCopy.lpBalance * (this.sliderValue / 100));
      } else {
        lpBalance = this.removeDataCopy.lpBalance;
      }
      // return
      if (this.isBnB(this.removeDataCopy.fromAddress)) {
        this.removeLiquidityETH(this.removeDataCopy.toAddress, lpBalance, amountOutMin, amountMin, this.account, deadline);
      } else if (this.isBnB(this.removeDataCopy.toAddress)) {
        this.removeLiquidityETH(this.removeDataCopy.fromAddress, lpBalance, amountMin, amountOutMin, this.account, deadline);
      } else {
        this.removeLiquidity(this.removeDataCopy.fromAddress, this.removeDataCopy.toAddress, lpBalance, amountMin, amountOutMin, this.account, deadline);
      }
    },
    handleSwapDetailClosed() {
      this.addliquidityDetailShow = false;
    },
    removeLiquidityETH(token, liquidity, amountTokenMin, amountETHMin, to, deadline) {
      console.log(token, liquidity, amountTokenMin, amountETHMin, to, deadline);
      this.removeLoading = true;
      this.isDel = true;
      const jackRouterContract = useJackRouterContract();
      jackRouterContract.methods
        .removeLiquidityETH(token, liquidity, amountTokenMin, amountETHMin, to, deadline).estimateGas({ from: this.account }).then(res => {
          jackRouterContract.methods
            .removeLiquidityETH(token, liquidity, amountTokenMin, amountETHMin, to, deadline)
            .send({
              from: to,
              gasPrice: web3.utils.numberToHex(this.gasPrice),
            })
            .on('transactionHash', hash => {
              const message = `${this.removeData.fromSymbol} ${this.$t('lang.swap99')} ${this.removeData.toSymbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.refulsh();
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('receipt', receipt => { })
            .on('confirmation', receipt => {
              if (this.isDel) {
                this.isDel = false;
                if (this.sliderValue == 100) {
                  this.deleteLocal();
                } else {
                  this.sliderValue = 0;
                  this.removeLoading = false;
                }
              }
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              this.removeLoading = false;
              throw error;
            });
        })
        .catch(err => {
          jackRouterContract.methods
            .removeLiquidityETHSupportingFeeOnTransferTokens(token, liquidity, amountTokenMin, amountETHMin, to, deadline)
            .send({
              from: to,
              gasPrice: web3.utils.numberToHex(this.gasPrice),
            })
            .on('transactionHash', hash => {
              const message = `${this.removeData.fromSymbol} ${this.$t('lang.swap99')} ${this.removeData.toSymbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.refulsh();
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('receipt', receipt => { })
            .on('confirmation', receipt => {
              if (this.isDel) {
                this.isDel = false;
                if (this.sliderValue == 100) {
                  this.deleteLocal();
                } else {
                  this.sliderValue = 0;
                  this.removeLoading = false;
                }
              }
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              this.removeLoading = false;
              throw error;
            });
        })




      // removeLiquidityETHSupportingFeeOnTransferTokens

    },
    async removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline) {
      console.log(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline);
      this.removeLoading = true;
      this.isDel = true;
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods.removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline).estimateGas({ from: this.account });
      jackRouterContract.methods
        .removeLiquidity(tokenA, tokenB, liquidity, amountAMin, amountBMin, to, deadline)
        .send({
          from: to, gas, gasPrice: web3.utils.numberToHex(this.gasPrice)
        })
        .on('transactionHash', hash => {
          const message = `${this.removeData.fromSymbol} ${this.$t('lang.swap99')} ${this.removeData.toSymbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.refulsh();
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('receipt', receipt => { })
        .on('confirmation', receipt => {
          if (this.isDel) {
            this.isDel = false;
            if (this.sliderValue == 100) {
              this.deleteLocal();
            } else {
              this.sliderValue = 0;
              this.removeLoading = false;
            }
          }
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.removeLoading = false;
          throw error;
        });
    },
    deleteLocal() {
      this.removeLoading = false;
      let list = [];
      list = localStorage.getItem('lpList') ? JSON.parse(localStorage.getItem('lpList')) : [];
      // let List = JSON.parse(localStorage.getItem('lpList'));
      for (let i = 0; i < list.length; i++) {
        if (list(i).lpAddress == this.removeData.lpAddress) {
          list.splice(i, 1);
        }
      }
      localStorage.setItem('lpList', JSON.stringify(list));
    },
    significantDigits,
    refulsh() {
      this.sliderValue = 0;
    },
    refulshLP() {
      this.getLpBalance(this.removeData);
    },
  },
  components: {
    AddliquidityDetail,
    PendingSwap,
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath', 'net', 'ColorCheckData']),
  },
};
</script>

<style lang="less" scoped>
.remove {
  border-radius: 30px 30px 0 0;
  background: var(--box-bg-color);

  // padding-bottom: 30px;
  .remove_top {
    font-family: PingFangSC-Semibold, PingFang SC;


    border-radius: 30px 30px 0 0;
    margin: 16px 0 10px;
    overflow: hidden;

    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      color: var(--text-color);
      border-bottom: 1px solid #272e39;
      padding: 22px 0 10px 0;
      margin: 0 18px 0 12px;

      .left_arrow {
        width: 44px;
        height: 44px;
        margin-right: 8px;
      }

      .install {
        width: 20px;
        height: 20px;
        margin-right: 14px;
      }

      .refresh {
        width: 20px;
        height: 20px;
      }

      .function_button {
        display: flex;
        font-size: 10px;
        color: var(--text-color);

        // margin: 6px 0 0 22px;
        .liquidity_title {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            font-size: 20px;
            font-family: Arial-Bold, Arial;
            font-weight: bold;
            color: var(--text-color);
            margin-bottom: 10px;
          }
        }
      }
    }

    .content {
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color);
      padding: 12px 26px 30px;

      .slider {
        border-radius: 16px;
        background: var(--line-bg-color);
        padding: 20px 16px 20px;
        margin: 24px 0 10px;

        .amount {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: var(--text-color);
        }

        .tips {
          font-size: 14px;
          font-family: Arial-Black, Arial;
          font-weight: 700;
          color: var(--text-color);
          margin: 40px 0 16px;
        }

        .slider_list {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;

          span {
            font-size: 12px;
            color: #fff;
            background: #3399ff;
            border-radius: 8px;
            padding: 6px 12px;
          }
        }
      }

      .iconDown {
        height: 18px;
        width: 18px;
        display: block;
        margin: 24px auto;
        cursor: pointer;
        // transform: rotate(90deg);
      }

      .exchange {
        border-radius: 16px;
        background: var(--line-bg-color);
        padding: 10px 16px 20px;

        .will_get {
          font-size: 14px;
          font-weight: 400;
          color: var(--text-color);
          margin-top: 10px;

          .headline {
            font-size: 14px;
            font-weight: bold;
            color: var(--text-color);
          }

          .detailed_data {
            padding: 16px 0;
            margin-top: 8px;
            color: var(--text-color)a9;

            .detailed {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;

              div {
                display: flex;
                align-items: center;
                color: var(--text-color);
              }

              .detailed_left {
                display: flex;
                align-items: center;
                font-weight: 14px;
                font-weight: bold;
                color: var(--text-color);

                .image {
                  display: block;
                  width: 24px;
                  height: 24px;
                  border-radius: 50%;
                  margin-right: 8px;
                }
              }
            }

            .receive {
              display: flex;
              justify-content: flex-end;
              font-size: 12px;
              color: #de9a7f;
            }
          }
        }
      }

      .button {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .nothing_swapBtn {
          width: 100%;
          background: rgba(102, 180, 174, 1);
          font-size: 16px;
          font-weight: bold;
          color: #fff;
          border-radius: 87px;
          border: 0;
          margin-top: 30px;
          padding: 30px 0;
        }
      }
    }
  }

  .remove_button {
    background: var(--box-bg-color);
    color: #fff;
    padding: 26px 20px;

    .title {
      font-size: 16px;
      font-weight: bold;
      color: var(--text-color);
    }

    .detailed {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      margin-top: 16px;

      div {
        display: flex;
        align-items: center;
        color: var(--text-color);
      }

      .quantity {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        color: var(--text-color);

        .img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 6px;
        }
      }

      .quantity_right {
        font-weight: bold;
        color: var(--text-color);
      }
    }
  }
}
</style>
