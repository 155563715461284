<template>
  <div class="engbill">
    <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
    <div class="level_card" v-loading="viewLoading"
      :style="`background: url(${levelList[summaryinfo.team_level].level_logo}) no-repeat;`">
      <div class="flex">
        <div style="display: flex;align-items: center;"><span
            style="font-size: 24px; color: rgba(0, 0, 0, 1);font-weight: 700;">{{
              $t(levelList[summaryinfo.team_level].name) }}</span> <van-tag round type="primary"
            :color="levelList[summaryinfo.team_level].color" style="margin-left: 7px;">+ {{
              parseFloat(summaryinfo.min_zone_accelerate * 100).toFixed(2) }} %</van-tag> </div>
        <div @click="Gostarguide"> {{ $t('lang.级别指南') }}</div>
      </div>
      <div class="flex">
        <div></div>

        <!-- <van-tag round v-if="summaryinfo.team_level<=0" type="primary" :color="levelList[summaryinfo.team_level].color" style="margin-left: 7px;font-size: 12px; padding: 2px 10px;" @click="paypopFun">  {{ $t('lang.升级') }}</van-tag>  -->
      </div>
      <div>
        <van-progress :percentage="ComRateNum" stroke-width="8" :color="levelList[summaryinfo.team_level].lineColor"
          :show-pivot="false" style="margin-bottom: 12px;" />
        <div class="flex">
          <div> {{ $t('lang.当前张数') }}{{ summaryinfo.children_num }}</div>
          <div> {{ $t('lang.距离') }} {{ $t(levelList[next_level_info.id].name) }}{{ $t('lang.需') }}{{
            next_level_info.team_hold - summaryinfo.children_num }} </div>
        </div>
      </div>

    </div>
    <div class="invite_box">
      <div class="title">
        {{ $t('lang.我的邀请码') }}
      </div>
      <div class="invite_box_line">
        {{ summaryinfo.parent_id > 0 ? UserInviteCode : $t('lang.请先绑定推荐人') }}
      </div>
      <van-button type="primary" v-if="summaryinfo.parent_id > 0" class="invite_box_line copys"
        color="rgba(102, 180, 174, 1)" @click="copy" :data-clipboard-text="UserInviteCode">
        <span style="color: #fff;"> {{ $t('lang.复制邀请码') }}</span>
      </van-button>
      <van-button type="primary" v-else class="invite_box_line" color="rgba(102, 180, 174, 1)" style="opacity: 0.6;">
        <span style="color: #fff;"> {{ $t('lang.复制邀请码') }}</span>
      </van-button>
    </div>
    <div class="invite_box" style="height: 170px;">
      <div class="title">
        {{ $t('lang.我的邀请人') }}
      </div>
      <div class="invite_box_line" @click="popupshowFun">
        {{ summaryinfo.parent_id > 0 ? UserRecommender : $t('lang.请填写推荐人地址') }}
      </div>

    </div>

    <div class="functional_region" id="myElementId">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
          :class="index == regionIndex ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>
    <div class="list" v-show="regionIndex == 0">
      <div class="li first">
        <div>{{ $t('lang.o127') }}</div>
        <div>{{ $t('lang.个人张数') }}</div>
        <div>{{ $t('lang.团队张数') }}</div>
      </div>
      <div class="li" v-for="(item, index) in childrenlist.maxlist" :key="index">
        <div style="color: rgba(0, 0, 0, 1)">{{ item.address }}</div>
        <div style="color: rgba(60, 186, 176, 1)">{{ item.self_num }}</div>
        <div style="color: rgba(42, 130, 228, 1)">{{ item.team_num }}</div>
      </div>
    </div>
    <div class="list" v-show="regionIndex == 1">
      <div class="li first">
        <div>{{ $t('lang.o127') }}</div>
        <div>{{ $t('lang.个人张数') }}</div>
        <div>{{ $t('lang.团队张数') }}</div>
      </div>
      <div class="li" v-for="(item, index) in childrenlist.otherlsit" :key="index">
        <div style="color: rgba(0, 0, 0, 1)">{{ item.address }}</div>
        <div style="color: rgba(60, 186, 176, 1)">{{ item.self_num }}</div>
        <div style="color: rgba(42, 130, 228, 1)">{{ item.team_num }}</div>
      </div>
    </div>
    <van-popup v-model="payshow" position="bottom" round closeable>
      <div class="paypop">
        <div class="title">
          {{ $t('lang.o117') }}
        </div>
        <div class="paypop_cont">
          <div class="paypop_checks">
            <div class="paypop_checks_box" @click="checkdataFun(0)">
              <span>{{ whitelistinfo.wl_pay_ol }} {{ banlace.olData.token }}</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == 0">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div>
            <div class="paypop_checks_box" @click="checkdataFun(1)">
              <span>{{ whitelistinfo.wl_pay_u }} {{ banlace.UData.token }}</span>
              <div class="checkbox">
                <img src="@/assets/imgsnowball/check_true.png" alt="" v-if="checkdata == 1">
                <img src="@/assets/imgsnowball/check_false.png" alt="" v-else>
              </div>
            </div>
          </div>
          <div class="banlace">
            <img src="@/assets/imgsnowball/banlce.png" alt="">
            <div class="banlace_box">
              <div style="font-size: 16px; color: rgba(0, 0, 0, 1); font-weight: 700; margin-bottom: 7px;">{{
                $t('lang.o118') }}</div>
              <div style="display: flex;">
                <div style="width: 80px;"> {{ banlace.olData.token }} {{ $t('lang.o119') }}：</div> {{
                  banlace.olData.usable }}
              </div>
              <div style="display: flex;">
                <div style="width: 80px;"> {{ banlace.UData.token }} {{ $t('lang.o119') }}：</div> {{
                  banlace.UData.usable }}
              </div>
            </div>
          </div>
          <div class="button" @click="nftwhitelistbuy">{{ $t('lang.o120') }}</div>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="popupshow" round>
      <div class="invite_box" style="width: 335px; border: none;">
        <div class="title">
          {{ $t('lang.填写邀请码') }}
        </div>
        <input type="text" class="invite_box_line" :placeholder="$t('lang.请输入邀请码')" v-model="InputInviteData">

        <van-button type="primary" class="invite_box_line" color="rgba(102, 180, 174, 1)" @click="nftuserbind"> <span
            style="color: #fff;"> {{ $t('lang.确认') }} </span> </van-button>

      </div>
    </van-popup>
  </div>
</template>

<script>
import { nft, user } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
import Clipboard from 'clipboard';
export default {
  data() {
    return {
      childrenlist: {
        maxlist: [],
        otherlsit: [],
      },
      checkdata: 0,
      InputInviteData: '',
      payshow: false,
      popupshow: false,
      levelList: [
        {
          name: 'lang.普通',
          level_logo: require('@/assets/imgsnowball/level_logo1.png'),
          color: 'rgba(42, 50, 77, 1)',
          lineColor: 'linear-gradient(90deg, rgba(188, 193, 209, 1) 0%, rgba(42, 50, 77, 1) 100%)'
        },
        {
          name: 'lang.R级',
          level_logo: require('@/assets/imgsnowball/level_logo2.png'),
          color: 'rgba(148, 118, 65, 1)',
          lineColor: 'linear-gradient(90deg, rgba(226, 204, 166, 1) 0%, rgba(178, 147, 96, 1) 100%)'

        },
        {

          name: 'lang.S级',
          level_logo: require('@/assets/imgsnowball/level_logo3.png'),
          color: 'rgba(80, 174, 179, 1)',
          lineColor: 'linear-gradient(90deg, rgba(176, 229, 232, 1) 0%, rgba(80, 174, 179, 1) 100%)'
        },
        {

          name: 'lang.SR级',
          level_logo: require('@/assets/imgsnowball/level_logo4.png'),
          color: 'rgba(189, 167, 57, 1)',
          lineColor: 'linear-gradient(90deg, rgba(252, 246, 212, 0.98) 0%, rgba(189, 167, 57, 1) 100%)'
        },
        {

          name: 'lang.SSR级',
          level_logo: require('@/assets/imgsnowball/level_logo5.png'),
          color: 'rgba(189, 122, 111, 0.98)',
          lineColor: 'linear-gradient(90deg, rgba(247, 224, 220, 0.98) 0%, rgba(189, 122, 111, 0.98) 100%)'
        },
        {

          name: 'lang.UR级',
          level_logo: require('@/assets/imgsnowball/level_logo6.png'),
          color: 'rgba(71, 84, 166, 0.98)',
          lineColor: 'linear-gradient(90deg, rgba(202, 206, 237, 0.98) 0%, rgba(71, 84, 166, 0.98) 100%)'
        }
      ],

      summaryinfo: {
        team_level: 0
      },
      next_level_info: {
        id: 1
      },
      regionIndex: 0,
      functionOption: [{ name: 'lang.最大社区' }, { name: 'lang.其它社区' }],
      whitelistinfo: {},
      banlace: {
        olData: 0,
        UData: 0
      },
      viewLoading: true,
      UserRecommender: '',
      UserInviteCode: ''
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
    ComRateNum() {
      if (this.next_level_info.team_hold <= 0) {
        return 100
      } else {
        return this.summaryinfo.children_num / this.next_level_info.team_hold * 100   || 0
      }

    }
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },
  components: {},
  created() {
    this.init();
  },
  mounted() { },
  methods: {
    init() {
      if (this.account) {
        this.viewLoading = true
        this.payshow = false
        if (this.$route.query.regionIndex) {
          this.regionIndex = this.$route.query.regionIndex;
        } else {
          this.regionIndex = 0
        }
        Promise.all([
          this.nftteaminfo(),
          this.nftwhitelistinfo(),
          this.getbanlace(),
          this.nftuserrecommender()
        ])

      }



    },
    checkdataFun(index) {

      this.checkdata = index
    },

    copy() {


      var clipboard = new Clipboard('.copys');
      clipboard.on('success', e => {
        this.$toast(this.$t('lang.swap300'));
        clipboard.destroy(); // 释放内存
      });
      clipboard.on('error', e => {
        this.$toast(this.$t('lang.swap301'));
        clipboard.destroy(); // 释放内存
      });

    },
    nftuserrecommender() {
      nft.nftuserrecommender(this.account).then(res => {
        if (res.code == 200) {
          this.UserRecommender = res.data.recommender
          this.UserInviteCode = res.data.invite_code
        }

      })
    },
    popupshowFun() {
      if (this.summaryinfo.parent_id > 0) return;
      this.popupshow = true
    },
    paypopFun() {

      this.payshow = true
    },
    getbanlace() {
      nft.walletinfobykey({ token_key: 'OL' }, this.account).then(res => {
        if (res.code == 200) {
          this.banlace.olData = res.data
        }

      })
      nft.walletinfobykey({ token_key: 'USDT' }, this.account).then(res => {
        if (res.code == 200) {
          this.banlace.UData = res.data
        }

      })
    },
    nftwhitelistinfo() {
      nft.nftwhitelistinfo(this.account).then(res => {
        this.whitelistinfo = res.data

      })
    },
    nftuserbind() {

      if (this.InputInviteData == '') return this.$toast('请填写邀请码');
      this.popupshow = false
      let data = {
        invite_code: this.InputInviteData
      }
      nft.nftuserbind(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.popupshow = false
        this.$toast(res.msg)
      })
    },

    async nftwhitelistbuy() {
      this.payshow = false
      let sign = null
      try {
        let web3 = new Web3(window.ethereum);
        sign = await web3.eth.personal.sign(web3.utils.fromUtf8('NFT Buy White List'), this.account);
      } catch (error) {
        return;
      }


      let data = {
        pay_type: this.checkdata,
        signature: sign
      }
      nft.nftwhitelistbuy(data, this.account).then(res => {
        if (res.code == 200) {
          this.init()
        }
        this.$toast(res.msg)
      })
    },

    nftteaminfo() {
      nft.nftteaminfo(this.account).then(res => {
        if (res.code == 200) {
          this.summaryinfo = res.data.level_info

          this.next_level_info = res.data.next_level_info
          this.viewLoading = false
          this.nftchildrenlist();
        }

      })
    },

    switchovers(index) {
      this.regionIndex = index;
    },
    Gostarguide() {
      this.$router.push({ name: 'starguide' });
    },
    toprouter() {
      this.$router.go(-1);
    },

    nftchildrenlist() {
      nft.nftchildrenlist({}, this.account).then(res => {
        let array = res.data;
        if (array != null) {
          const valueToMatch = this.summaryinfo.max_zone_num;
          const keyToCheck = 'team_num';
          let matchedArray = [];
          let unmatchedArray = [];

          let found = false;
          unmatchedArray = array.filter(item => {
            if (!found && item[keyToCheck] === valueToMatch) {
              matchedArray.push(item);
              found = true;
              return false; // 不加入unmatchedArray
            }
            return true; // 加入unmatchedArray
          });
          unmatchedArray.sort((a, b) => {
            if (a[keyToCheck] < b[keyToCheck]) {
              return 1;
            }
            if (a[keyToCheck] > b[keyToCheck]) {
              return -1;
            }
            return 0;
          });

          this.childrenlist.maxlist = matchedArray;
          this.childrenlist.otherlsit = unmatchedArray;
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.invite_box {
  margin-top: 16px;
  padding: 25px 15px;
  width: 100%;
  height: 257px;
  opacity: 1;
  border-radius: 16px;
  border: 1px solid rgba(240, 240, 240, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .title {
    font-size: 20px;
    font-weight: 700;
    color: rgba(34, 34, 34, 1);
  }

  .invite_box_line {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    line-height: 60px;
    height: 60px;
    border-radius: 110px;
    background: rgba(240, 240, 240, 1);
    border: none;
  }
}

.functional_region {
  margin-top: 12px;
  padding-top: 18px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);

  .region {
    display: flex;

    align-items: center;

    div {
      margin-right: 28px;
      color: rgba(153, 153, 153, 1);
    }

    .special {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
      position: relative !important;

      &::before {
        position: absolute !important;
        width: 36px;
        height: 2px;
        border-radius: 25px;
        background: rgba(56, 248, 252, 1);
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.engbill {
  padding: 20px;
  padding-top: 52px;
  padding-bottom: 44px;
}

.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 20px 0px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.list {
  margin-top: 29px;
  padding: 15px 20px;
  width: 100%;
  border-radius: 16px;
  background: rgba(247, 247, 247, 1);


  .first {
    color: rgba(153, 153, 153, 1);
    padding-bottom: 13px;
    border-bottom: 1px solid rgba(222, 222, 222, 1);
  }

  .li {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    font-size: 14px;
    font-weight: 700;

    &>div {
      width: calc(100% / 3);
      text-align: center;
    }

    &>div:first-child {
      text-align: left;
    }

    &>div:last-child {
      text-align: right;
    }
  }
}

.imgboxs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);

    img {
      width: 49px;
      height: 47px;
      margin-bottom: 11px;
    }
  }

  .line {
    width: 42px;
    height: 1px;
    border-bottom: 1px dotted rgba(56, 248, 252, 1);
  }
}

.level_card {
  padding: 20px;
  width: 100%;
  height: 152px;
  background: url('~@/assets/imgsnowball/level_logo1.png') no-repeat;
  background-size: contain !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;

  }
}

.paypop {
  width: 100%;

  .title {
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    padding: 19px 20px;
    color: rgba(34, 34, 34, 1);
    border-bottom: 1px solid rgba(242, 242, 242, 1);
  }

  .paypop_cont {
    padding: 40px 21px;

    .button {
      margin-top: 23px;
      height: 48px;
      opacity: 1;
      border-radius: 110px;
      background: rgba(102, 180, 174, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
    }

    .banlace {
      padding-bottom: 7px;
      border-bottom: 1px solid rgba(242, 242, 242, 1);
      display: flex;

      img {
        width: 20px;
        height: 20px;
        margin-right: 12px;
      }

      .banlace_box {
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
      }
    }

    .paypop_checks {
      width: 100%;

      .paypop_checks_box {
        padding: 14px 19px;
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 0, 0, 1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;

        border: 1px solid rgba(230, 230, 230, 1);
        margin-bottom: 14px;

        .checkbox {
          width: 14px;
          height: 14px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>