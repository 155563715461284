<template>
    <div class="engbill">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="title">
            {{ $t('lang.质押激励') }}
        </div>
        <div class="receivebox" style="background:  linear-gradient(138.26deg, #8AD4CD 0%, #66B4AE 100%);"  v-loading="loading">
            <img src="@/assets/img/receivebox_mint1.png" alt="">
            <div class="num">
                <div class="num_title">{{ $t('lang.已领取') }}</div>
                <div class="num_num" style="opacity: 0.6;">0</div>
            </div>
            <div class="num">
                <div class="num_title">{{ $t('lang.待领取') }}</div>
                <div class="num_num">0</div>
            </div>
            <div class="button">
                {{ $t('lang.领取') }}
            </div>
        </div>
        <div class="title">
            {{ $t('lang.社区激励') }}
        </div>
        <div class="receivebox" style="background: linear-gradient(138.26deg, #A3AADE 0%, #5D68B3 100%);" v-loading="loading">
            <img src="@/assets/img/receivebox_mint2.png" alt="">
            <div class="num">
                <div class="num_title">{{ $t('lang.已领取') }}</div>
                <div class="num_num" style="opacity: 0.6;">0</div>
            </div>
            <div class="num">
                <div class="num_title">{{ $t('lang.待领取') }}</div>
                <div class="num_num">0</div>
            </div>
            <div class="button">
                {{ $t('lang.领取') }}
            </div>
        </div>
        <div class="title">
            {{ $t('lang.分享激励') }}
        </div>
        <div class="receivebox" style="background:  linear-gradient(138.26deg, #C4D9AB 0%, #93C259 100%);" v-loading="loading">
            <img src="@/assets/img/receivebox_mint3.png" alt="">
            <div class="num">
                <div class="num_title">{{ $t('lang.已领取') }}</div>
                <div class="num_num" style="opacity: 0.6;">0</div>
            </div>
            <div class="num">
                <div class="num_title">{{ $t('lang.待领取') }}</div>
                <div class="num_num">0</div>
            </div>
            <div class="button">
                {{ $t('lang.领取') }}
            </div>
        </div>
        <div class="title">
            {{ $t('lang.榜单奖励') }}
        </div>
        <div class="receivebox" style="background: linear-gradient(138.26deg, #F6E167 0%, #E3B626 100%);" v-loading="loading">
            <img src="@/assets/img/receivebox_mint4.png" alt="">
            <div class="num">
                <div class="num_title">{{ $t('lang.已领取') }}</div>
                <div class="num_num" style="opacity: 0.6;">0</div>
            </div>
            <div class="num">
                <div class="num_title">{{ $t('lang.待领取') }}</div>
                <div class="num_num">0</div>
            </div>
            <div class="button">
                {{ $t('lang.领取') }}
            </div>
        </div>
        <div class="functional_region" id="myElementId">
            <div class="region">
                <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(index)"
                    :class="index == regionIndex ? 'special' : ''">
                    <span>{{ $t(item.name) }}</span>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="li first">
                <div>{{ $t('lang.o136') }}</div>
                <div>{{ $t('lang.地址') }}</div>
                <div>{{ $t('lang.状态') }}</div>
                <div>{{ $t('lang.o137') }}</div>
               
            </div>
            <van-list v-model="recordlist.loading" :finished="recordlist.finished" :finished-text="`${$t('lang.d145')}`"
                @load="onLoad">
                <div class="li" v-for="(item, index) in recordlist.list" :key="index">
                    <div style="color: rgba(153, 153, 153, 1);">{{ item.created_at }}</div>
                    <div style="color: rgba(73, 140, 147, 1);">
                        <span v-if="regionIndex == 0">+{{ item.reward }}</span>
                        <span v-else>{{ item.usable_change < 0 ? item.usable_change : '+' + item.usable_change }}</span>
                    </div>
                    <div style="color: rgba(153, 153, 153, 1);" v-if="regionIndex == 0">
                        <div v-if="item.status == 0">
                            <van-tag round type="primary" color="rgba(102, 180, 174, 1)" style="padding: 3px 10px;"
                                @click="nftstaticrewardclaim(item.id)">{{ $t('lang.领取') }}</van-tag>
                        </div>
                        <div v-else-if="item.status == 1" style="color: rgba(73, 140, 147, 1);">
                            {{ $t('lang.已到账') }}
                        </div>
                        <div v-else-if="item.status == 2" style="color: rgba(121, 72, 234, 1);">
                            {{ $t('lang.d207') }}
                        </div>
                    </div>
                    <div style="color: rgba(153, 153, 153, 1);" v-else>{{ item.type_txt }}</div>
                </div>
            </van-list>

        </div>
    </div>
</template>

<script>
import { nft } from '../../api/index';
import { mapState } from 'vuex';
import Web3 from 'web3';
export default {
    data() {
        return {
            functionOption: [{ name: 'lang.质押激励' }, { name: 'lang.社区激励' }, { name: 'lang.分享激励' }, { name: 'lang.榜单奖励' }],
            regionIndex: '0',
            loading: false,
            recordlist: {
                loading: true,
                finished: true,
                list: [],
                info: {
                    page: 1,
                    page_size: 10,
                    type: '0'
                }

            }
        }
    },
    components: {

    },
    computed: {
        ...mapState(['account', 'lang']),
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    mounted() {

    },
    methods: {
        switchovers(index) {
            this.regionIndex = index;


        },
        toprouter() {
            this.$router.go(-1);

        },


        init() {

        },
        onLoad() {

        },



    },
}

</script>
<style scoped lang='less'>
.functional_region {

    .region {
        display: flex;

        align-items: center;
        justify-content: space-between;
        width: 100%;
        color: rgba(96, 96, 96, 1);
        font-size: 13px;

        .special {
            font-size: 18px;
            color: rgba(0, 0, 0, 1);
        }
    }
}

.engbill {
    padding: 20px;
    padding-top: 52px;
    padding-bottom: 44px;

}

.receivebox {
    margin-bottom: 28px;
    flex-wrap: wrap;
    padding: 20px 15px 20px 8px;
    width: 100%;
    height: 95px;
    opacity: 1;
    border-radius: 8px;
    background: linear-gradient(138.26deg, rgba(138, 212, 205, 1) 0%, rgba(102, 180, 174, 1) 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        width: 56px;
        height: 56px;
    }

    .button {
        align-self: end;
        padding: 2px 12px;
        border-radius: 4px;
        background: rgba(255, 255, 255, 1);
        font-size: 14px;
        font-weight: 500;
        color: rgba(102, 61, 184, 1);
    }

    .num {
        .num_title {
            font-size: 12px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.6);
        }

        .num_num {
            font-size: 18px;
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
        }
    }
}

.title {
    font-size: 18px;
    font-weight: 700;
    color: rgba(0, 0, 0, 1);
    margin-bottom: 12px;
}

.recruit_title {
    color: var(rgba(255, 255, 255, 1));
    padding: 20px 0px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list {
    margin-top: 29px;
    padding: 15px 20px;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    border-radius: 16px;
    background: rgba(247, 247, 247, 1);


    .first {
        color: rgba(153, 153, 153, 1);
        padding-bottom: 13px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }

    .li {
        margin-bottom: 15px;
        width: 100%;
        display: flex;
        font-size: 14px;
        font-weight: 700;

        &>div {
            width: calc(100% / 3);
            text-align: center;
        }

        &>div:first-child {
            text-align: left;
        }

        &>div:last-child {
            text-align: right;
        }
    }
}
</style>