<template>
  <div class="index">
    <div class="banner">
      <div>{{ $t('lang.h52') }}</div>
      <div>{{ $t('lang.h53') }}</div>

      <van-swipe class="my-swipe" style="min-height: 98px; width: 100%;" :autoplay="3000" indicator-color="white"
        :show-indicators="false">
        <van-swipe-item v-for="(item, index) in imglist" :key="index">
          <img :src="getFullImagePath(item.image)" alt="" style="border-radius: 16px; height: 98px; width: 100%;" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="functional_region">
      <div class="region">
        <div v-for="(item, index) of functionOption" :key="index" @click="switchovers(item.router[0])"
          :class="item.router.indexOf($route.name) != -1 ? 'special' : ''">
          <span>{{ $t(item.name) }}</span>
        </div>
      </div>
    </div>

    <div class="box">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { basic, homedata } from '@/api/index';
import { getFullImagePath } from '@/utils/common';
import { watch } from 'less';
export default {
  data() {
    return {
      // 功能列表
      functionOption: [
        {
          name: 'lang.swap77',
          router: ['Swap']
        },
        {
          name: 'lang.swap78',
          router: ['LiquidityIndex','Liquidity','importMining','Remove']
        },
        {
          name: 'PANCAKE',
          router: ['SwapV2']
        }],
      regionIndex: '0',
      imglist: []
    };
  },
  computed: {
    ...mapState(['account', 'lang']),
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },
  created() {
    this.init();
  },
  methods: {
    getFullImagePath,
    init() {
      if (this.$route.query.id) {
        this.regionIndex = this.$route.query.id;
      }
      if (this.account) {
        this.getbance();
      }
    },

    getbance() {
      homedata.getbancer({ type: 1 }, this.account).then(res => {
        this.imglist = res.data || null;
      });
    },
    switchovers(name) {
      this.$router.push({ name });

    },
  },
};
</script>

<style lang="less" scoped>
.index {
  display: flex;
  flex-direction: column;
  background: var(--bg-color);
  min-height: 100vh;
  width: 100%;

  .banner {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &>div {
      color: var(--text-color);
      font-weight: bold;
    }

    &>div:nth-child(1) {
      font-size: 24px;
      margin-bottom: 5px;
    }

    &>div:nth-child(2) {
      font-size: 18px;
      margin-bottom: 19px;
    }

    .img {
      position: relative;
      width: 100%;

      // background: url('~@/assets/img/swap_banner.png') no-repeat;
      background-size: contain !important;
      height: 98px;

      .img_abs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 20px;
        padding: 20px 18px;
        text-align: left;
        width: 50%;
        height: 98px;
        background: #fff;
        font-size: 20px;
        color: rgba(102, 180, 174, 1);
      }
    }
  }
}

.box {
  border-radius: 30px 30px 0 0;
  background: var(--box-bg-color);
}

.functional_region {

  padding: 20px 20px 0;
  padding-top: 0;

  .region {
    display: flex;
    width: 100%;
    line-height: 22px;
    font-size: 16px;
    border-radius: 30px;
    padding: 3px;
    border: 1px solid rgba(102, 180, 174, 1);
    font-family: PingFangSC-Semibold, PingFang SC;
    color: rgba(158, 156, 163, 1);
    font-weight: 600;
    // background:  rgba(69, 131, 255, 1);
    border-radius: 34px;
    // margin: 20px 0;
    margin-bottom: 20px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      // width: 192rpx;
      padding: 16px 0;
    }

    .special {
      // width: 50%;
      color: #fff;
      background: rgba(102, 180, 174, 1);
      // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
      border-radius: 34px;
    }
  }
}

/deep/ .router {
  // background: #000 !important;
}
</style>