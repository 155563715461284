<template>
  <div class="home_page">
    <div class="snowballmain">
      <van-swipe class="my-swipe" style="min-height: 166px;" :autoplay="3000" indicator-color="white" :show-indicators="false">
        <van-swipe-item v-for="(item, index) in imglist" :key="index">
          <img :src="`${imgurl}${item.image}`" alt="" class="homeimg" />
        </van-swipe-item>
      </van-swipe>
      <!-- <img src="@/assets/img/test_banner.png" alt="" class="homeimg" /> -->

      <div class="routerlink">
        <router-link class="notice" :to="{ name: 'xiangqing', query: { id: notice.group } }">
          <img src="@/assets/imgsnowball/notice.png" alt="" />
          <span style="color: var(--text-color)">{{ notice.title }}</span>
          <router-link :to="{ name: 'gongao', query: { type: 0 } }" class="shoyi"
            style="background: rgba(102, 180, 174, 1)">
            <div style="transform: rotate(-90deg); color: #fff">
              <van-icon name="down" />
            </div>
          </router-link>
        </router-link>
        <div class="router_li" v-for="(item, index) in routerlist" @click="bNav(item.router, item.i)" :key="index">
          <div class="imgbox">
            <div class="imgbox_item">
              <img :src="ColorCheckData == 'black' ? item.image : item.image1" alt="" />
            </div>
          </div>

          <span>{{ $t(item.name) }}</span>
        </div>
      </div>

    
     
      <div class="zichantitle">
        <span class="page_title" style="margin-right: 13px">{{ $t('lang.d16') }}</span>
     
        <van-checkbox v-model="checked" shape="square" style="color: #fff;" icon-size="14px" checked-color="rgba(102, 180, 174, 1)"><span
            class="yingcang">{{ $t('lang.d17') }}</span></van-checkbox>
      </div>
      <div class="zichanlist">
        <div class="li_title">
          <div>{{ $t('lang.h9') }}</div>
          <div>{{ $t('lang.h38') }}</div>
          <div>{{ $t('lang.d191') }}</div>
        </div>
        <div v-if="checked">
          <div class="li_con" v-for="(item, index) in walletFitZeroList" style="color: #000" :key="index">
            <div class="li1">
              <img :src="imgurl + item.logo" alt="" />
              <span>{{ item.token }}</span>
            </div>
            <div>{{ item.usable }}</div>
            <div>{{ item.balance }}</div>
          </div>
        </div>
        <div v-else>
          <div class="li_con" v-for="(item, index) in walletList" style="color: #000" :key="index">
            <div class="li1">
              <img :src="imgurl + item.logo" alt="" />
              <span>{{ item.token }}</span>
            </div>
            <div>{{ item.usable }}</div>
            <div>{{ item.balance }}</div>
          </div>
        </div>
      </div>
      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">{{ $t('lang.h124') }}</div>
      <div class="Advantage">
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage1.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h96') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h97') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage2.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h98') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h99') }}</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage3.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h100') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h101') }} ！</div>
        </div>
        <div class="Advantage_box">
          <img src="@/assets/img/Advantage4.png" alt="" />
          <div class="Advantage_box_title">{{ $t('lang.h102') }}</div>
          <div class="Advantage_box_text">{{ $t('lang.h103') }}</div>
        </div>
      </div>

      <div class="page_title" style="margin-top: 30px; margin-bottom: 12px">{{ $t('lang.h104') }}</div>
      <div class="routemap2">
        <div class="routemap2_line">
          <div class="routemap2_line_box" style="margin-right: 30px">
            <div class="routemap2_line_box_title">{{ $t('lang.h105') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h106') }}</div>
          </div>
        </div>
        <div class="routemap2_line">
          <div class="routemap2_line_box">
            <div class="routemap2_line_box_title">{{ $t('lang.h107') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h108') }}</div>
          </div>
        </div>
        <div class="routemap2_line">
          <div class="routemap2_line_box">
            <div class="routemap2_line_box_title">{{ $t('lang.h109') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h110') }}</div>
          </div>
        </div>
        <div class="routemap2_line">
          <div class="routemap2_line_box">
            <div class="routemap2_line_box_title">{{ $t('lang.h111') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h112') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h113') }}</div>
          </div>
        </div>
        <div class="routemap2_line">
          <div class="routemap2_line_box">
            <div class="routemap2_line_box_title">{{ $t('lang.h114') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h115') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h116') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h117') }}</div>
          </div>
        </div>
        <div class="routemap2_line">
          <div class="routemap2_line_box" style="margin-left: 50px">
            <div class="routemap2_line_box_title">{{ $t('lang.h118') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h119') }}</div>
            <div class="routemap2_line_box_text">{{ $t('lang.h120') }}</div>
          </div>
        </div>
      </div>
      <van-popup v-model="gongaoshow" round closeable>
        <div class="o_cont">
          <div class="title">{{ $t('lang.d98') }}</div>
          <div class="line"></div>
          <div class="content">
            <div style="color: var(--text-color); font-size: 16px">{{ notice.title }}</div>
            <div style="color: #888a8e; font-size: 12px">{{ notice.created_time }}</div>
            <div v-html="notice.content" style="color: var(--text-color); font-size: 14px"></div>
          </div>
        </div>
      </van-popup>
    </div>

    <div class="universal_box merchandise_bg">
      <img class="abs_img" src="@/assets/img/merchandise_icon.png" alt="" />
      <div class="clcaption">
        <span>{{ $t('lang.swap88') }}</span>
      </div>
      <div class="slogan">
        <span>{{ $t('lang.h123') }}</span>
        <span>{{ $t('lang.h13') }}</span>
      </div>
      <div class="zhuzao_button" style="padding-left: 18px; margin-top: 14px; margin-bottom: 27px"
        @click="bNav('Swap')">
        <span>{{ $t('lang.swap88') }}</span>
        <div class="down_icon"><van-icon name="down" /></div>
      </div>
    </div>
    <div class="universal_box">
      <img class="abs_img" src="@/assets/img/earncoins_icon.png" alt=""
        style="right: -20px; width: 158px; height: 124px" />
      <div class="clcaption">
        <span>{{ $t('lang.swap143') }}</span>
      </div>
      <div class="slogan">
        <span>{{ $t('lang.swap290') }}</span>
        <span>{{ $t('lang.swap291') }}</span>
      </div>

      <div class="zhuzao_button" style="padding-left: 18px; margin-top: 14px; margin-bottom: 27px"
        @click="bNav('earn')">
        <span>{{ $t('lang.swap190') }}</span>
        <div class="down_icon"><van-icon name="down" /></div>
      </div>
    </div>
    <div class="universal_box">
      <img class="abs_img" src="@/assets/img/emission_icon.png" alt=""
        style="right: -20px; width: 146.15px; height: 134.71px" />
      <div class="clcaption">
        <span>{{ $t('lang.swap292') }}</span>
      </div>
      <div class="slogan">
        <span>ONE DEX {{ $t('lang.swap293') }}</span>
        <span>{{ $t('lang.swap294') }}</span>
      </div>
      <div class="zhuzao_button" style="padding-left: 18px; margin-top: 14px; margin-bottom: 27px" @click="bNav('iso')">
        <span>{{ $t('lang.swap295') }}</span>
        <div class="down_icon"><van-icon name="down" /></div>
      </div>
    </div>

    <div class="partner_list">
      <div class="partner">
        <div class="page_title">
          <span>{{ $t('lang.swap164') }}</span>
        </div>
        <div class="partner_img">
          <div class="img" v-for="(item, index) of institutionsListB" :key="index">
            <img :src="ColorCheckData == 'black' ? item.image : item.image1" />
          </div>
        </div>
      </div>
      <div class="partner">
        <div class="page_title">
          <span>{{ $t('lang.swap165') }}</span>
        </div>
        <div class="partner_img">
          <div class="img" v-for="(item, index) of institutionsListC" :key="index">
            <img :src="ColorCheckData == 'black' ? item.image : item.image1" />
          </div>
        </div>
      </div>

      <div class="Icon_img">
        <img v-for="(item, index) of institutionsListIcon" :key="index" :src="item" class="img" />
      </div>
      <div class="reserved">
        <span>Copyright@2024 ONE DEX.All rights reserved.</span>
      </div>
    </div>
  </div>
</template>

<script>

import { getErc20Contract, } from '@/utils/contractHelp';
import { mapState } from 'vuex';
import { invite, homedata, user, bizhong, moneymask, gongao } from '@/api/index';
import Web3 from 'web3';
export default {
  data() {
    return {
      notice: {},
      gongaoshow: false,
      imgurl: '',
      imglist: [],
      checked: false,
      walletList: [],
      walletFitZeroList: [],
      routerlist: [
        {
          image: require('@/assets/imgsnowball/chobi1.png'),
          image1: require('@/assets/imgsnowball/chobi.png'),
          name: 'lang.h87',
          router: 'ChoBi1',
          i: '0',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/tibi1.png'),
          image1: require('@/assets/imgsnowball/tibi.png'),
          name: 'lang.h88',
          router: 'ZhuangZhan3',
          i: '2',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/zhuanzhang1.png'),
          image1: require('@/assets/imgsnowball/zhuanzhang.png'),
          name: 'lang.swap1',
          router: 'shandui',
          // i: '2',
          IsOpen: true,
        },
        {
          image: require('@/assets/imgsnowball/zhangdan1.png'),
          image1: require('@/assets/imgsnowball/zhangdan.png'),
          name: 'lang.d4',
          router: 'zhangdan',
          IsOpen: true,
        },
      ],
      page: 1,

      D6price: 0,
      death: 0,
      myBalance: 0,
      chainInviteInfo: '',
      profit: 0,
      swanPrice: 0,
      institutionsListA: [
        require('@/assets/img/icon/icon1.png'),
        require('@/assets/img/icon/icon2.png'),
        require('@/assets/img/icon/icon3.png'),
        require('@/assets/img/icon/icon4.png'),
        require('@/assets/img/icon/icon5.png'),
        require('@/assets/img/icon/icon6.png'),
      ],
      institutionsListB: [
        {
          image: require('@/assets/img/slowmist.png'),
          image1: require('@/assets/img/slowmist1.png'),
        },
        {
          image: require('@/assets/img/certik.png'),
          image1: require('@/assets/img/certik1.png'),
        },
      ],
      institutionsListC: [
        {
          image: require('@/assets/img/binance.png'),
          image1: require('@/assets/img/binance1.png'),
        },
        {
          image: require('@/assets/img/okchain.png'),
          image1: require('@/assets/img/okchain1.png'),
        },
        {
          image: require('@/assets/img/trustwallet.png'),
          image1: require('@/assets/img/trustwallet1.png'),
        },
        {
          image: require('@/assets/img/metamask.png'),
          image1: require('@/assets/img/metamask1.png'),
        },
        {
          image: require('@/assets/img/coinmarketcap.png'),
          image1: require('@/assets/img/coinmarketcap1.png'),
        },
        {
          image: require('@/assets/img/bitkeep.png'),
          image1: require('@/assets/img/bitkeep1.png'),
        },
        {
          image: require('@/assets/img/tokenpocket.png'),
          image1: require('@/assets/img/tokenpocket1.png'),
        },
        {
          image: require('@/assets/img/coingecko.png'),
          image1: require('@/assets/img/coingecko1.png'),
        },
        {
          image: require('@/assets/img/imtoken.png'),
          image1: require('@/assets/img/imtoken1.png'),
        },
        {
          image: require('@/assets/img/openchat.png'),
          image1: require('@/assets/img/openchat1.png'),
        },
      ],
      institutionsListIcon: [require('@/assets/img/IconA.png'), require('@/assets/img/IconB.png'), require('@/assets/img/IconC.png')],
      allReword: 0,
      site: '',
      mapShow: false, //发展路线图状态

      phoPrice: 0,
      tokenqutoe: [],
      tokenqutoe2: [],
      tokenimgurls: '',
      timer: null,
    };
  },

  created() {
    if (this.account) {
      this.init();
    }

    this.scrollLoad();
    this.scrollLoadmain();
  },
  mounted() {
    window.addEventListener('scroll', this.scrollLoad, true);
    window.addEventListener('scroll', this.scrollLoadmain, true);
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    window.removeEventListener('scroll', this.scrollLoad, true);
    window.removeEventListener('scroll', this.scrollLoadmain, true);
  },
  watch: {
    account() {
      this.init();
    },
    lang() {
      this.init();
    },
  },

  methods: {
    bNav(router, i = '') {
      if (router != '') {
        if (i != '') {
          this.$router.push({ name: router, query: { id: i } });
        } else {
          if (router == 'superone') {
            window.location.href = 'https://supper.onedex.club/pages/login/index';
          }else if(router == 'HamsterGame'){
            window.location.href = 'https://game.kami-wallet.com/';
          } else {
            this.$router.push({ name: router });
          }
        }
      } else {
        this.$toast(this.$t('lang.swap65'));
      }
    },
    getbance() {
      homedata.getbancer({type:0},this.account).then(res => {
        this.imglist = res.data;
      });
    },
    isfirstgongao() {
      if (this.HomeNOticeInfo.id != this.notice.group) {

        const givenDate = new Date(this.notice.created_at);
        const currentDate = new Date();

        const timeDifference = currentDate - givenDate;
        const isWithin4Days = timeDifference <= 4 * 24 * 60 * 60 * 1000;
        if (isWithin4Days) {
          this.gongaoshow = true;
        } else {
          this.gongaoshow = false;
        }
        this.$store.commit('SETGONGAOID', {
          id: this.notice.group,
          time: this.notice.created_at,
          isWithin4Days
        });
      } else {
        this.gongaoshow = false;
      }
    },
    gongaox() {
      this.gongaoshow = false;
    },
    getnewnotice() {
      gongao.getNewNotice({ type: 0 }).then(res => {
        this.notice = res.data;
        this.isfirstgongao();
      });
    },
    getqianbao() {
      moneymask.getqianbao(this.account).then(res => {
        if (res.code == 200) {
          this.geterc20banceof(res.data);
        }

      });
    },
    async geterc20banceof(list) {

      let web3 = new Web3(window.ethereum);
      this.walletList = list;

      const balancePromises = list.map(async item => {
        try {
          if (item.contract != '') {
            const erc20Contract = getErc20Contract(item.contract);
            let num = await erc20Contract.methods.balanceOf(this.account).call();
            num = web3.utils.fromWei(num, 'ether');
            return {
              ...item,
              balance: parseFloat(num).toFixed(3) // 修正拼写错误
            };
          } else {
            return {
              ...item,
              balance: 0
            };
          }
        } catch (error) {
          return {
            ...item,
            balance: 0 // 可以用一个特定的字符串标记失败
          };
        }
      });

      const balances = await Promise.all(balancePromises);
      // 更新列表，这需要根据实际情况进行调整，确保更新逻辑正确
      this.walletList = balances.map(balance => ({
        ...balance,
        balance: balance.balance // 保持外部行为不变
      }));
      this.walletList.forEach(item => {
        if (item.usable > 0) {
          this.walletFitZeroList.push(item);
        }
      })

    },
    getimgurl() {
      homedata.gettokenimgurl().then(res => {
        this.imgurl = res.data.image_domain;
      });
    },

    myBalancefun() {
      let data = {
        token_id: 5,
      };
      moneymask.getwalletgetinfo(data, this.account).then(res => {
        this.myBalance = res.data.usable;
      });
    },
    tokenq() {
      homedata.gettokenquote(this.account).then(res => {
        var a = res.data;
        this.tokenqutoe = a.slice(0, 4);
        for (item in this.tokenqutoe) {
          if (item.token_id == 5) {
            this.D6price = item.last;
          }
        }
        this.tokenqutoe2 = this.tokenqutoe.slice(4);
      });
      homedata.gettokenimgurl().then(res => {
        this.tokenimgurls = res.data.image_domain;
      });
    },
    scrollLoad() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.img');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },

    scrollLoadmain() {
      // 1.获取所有的box
      var boxList = document.querySelectorAll('.universal_box');
      //2. 定义一个目标值
      var targetValue = window.innerHeight * 0.8;

      //3.获取每一个box距离浏览器顶部的值
      boxList.forEach(function (box) {
        var boxTop = box.getBoundingClientRect().top;

        if (boxTop <= targetValue) {
          box.classList.add('show-center');
        } else {
          box.classList.remove('show-center');
        }
      });
    },
    async init() {
      if (this.account) {
        this.getimgurl();
        this.getbance();
        this.getqianbao();
        this.getnewnotice();
      }
    },

    without() {
      this.$router.push({ name: 'Mining' });
    },
    toSwap() {
      this.$router.push({ name: 'Swap' });
    },

    mapclick() {
      this.mapShow = !this.mapShow;
    },
  },
  computed: {
    ...mapState(['account', 'lang', 'HomeNOticeInfo', 'ColorCheckData']),
  },
};
</script>

<style lang="less" scoped>
.c2cBoxs {
  margin: 19px 0 27px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box {
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
    border-radius: 9.4px;
    padding: 12px 14px;
    width: 48%;
    height: 94px;
    display: flex;
    justify-content: space-between;
    background: url('~@/assets/img/c2c_box_bg1.png') no-repeat;
    background-size: contain;

    &:nth-child(2) {
      background: url('~@/assets/img/c2c_box_bg2.png') no-repeat;
    }

    .zhuzao_button {
      background: rgba(0, 0, 0, 1);
      width: 72px;
      height: 30px;
      align-self: flex-end;
      font-size: 12px;
      font-weight: 700;

      .down_icon {
        width: 26px;
        height: 26px;
      }
    }
  }
}

.routemap2 {
  padding: 27px 9px 20px 9px;
  width: 100%;
  background: url('~@/assets/img/routemap2_bg.png') no-repeat;
  background-size: contain;
  height: 449px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  img {
    position: absolute;
    left: -40%;
    bottom: 16px;
    width: 228px;
    height: 133px;
  }

  .routemap2_line {
    width: 100%;

    text-align: left;

    &:nth-child(2n) {
      .routemap2_line_box {
        float: left;
      }

      text-align: right;
    }

    .routemap2_line_box {
      font-size: 12px;
      float: right;

      .routemap2_line_box_title {
        color: rgba(0, 0, 0, 1);
      }

      .routemap2_line_box_text {
        color: rgba(110, 113, 117, 1);
      }
    }
  }
}

.Advantage {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .Advantage_box {
    border-radius: 16px;
    background: rgba(247, 247, 247, 1);
    padding: 11px;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%;
    margin-bottom: 16px;
    padding-top: 10px;

    img {
      width: 116px;
      height: 116px;
    }

    .Advantage_box_title {
      font-size: 18px;
      color: rgba(0, 0, 0, 1);
      font-weight: 700;
      margin-bottom: 10px;
    }

    .Advantage_box_text {
      font-size: 12px;
      line-height: 16.5px;
      color: rgba(0, 0, 0, 0.8);
    }
  }
}

.zhuzao_button {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 91px;
  height: 36px;
  opacity: 1;
  border-radius: 36px;
  padding: 2px;
  background: rgba(102, 180, 174, 1);

  .down_icon {
    transform: rotate(-90deg);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: var(--bg-color);
    color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.page_title {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 27px;
  color: var(--text-color);
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 0;
    width: 72px;
    height: 2px;
    opacity: 1;
    background: linear-gradient(90deg, rgba(102, 180, 174, 1) 0%, rgba(60, 122, 134, 0) 100%);
  }
}

.home_page {
  position: relative;
  background: rgba(255, 255, 255, 1);
  padding: 0 0 20px;
  overflow-x: hidden;
  margin-top: 30px;

  .gradient {
    font-weight: bold;
    background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .explanation {
    position: relative;
    font-size: 14px;
    // font-weight: bold;
    color: #b8b8b8;
    z-index: 1;
    padding: 30px 20px;

    p {
      color: var(--text-color);
      font-size: 28px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: left;
      line-height: 30px;
    }

    span {
      color: #5883bfff;
      font-size: 12px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }
  }

  .route_map {
    position: relative;
    margin: 0 auto;
    width: 334px;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    opacity: 1;
    background: var(--box-bg-color);
    padding: 20px;
    z-index: 1;

    .map {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: var(--text-color);
      font-weight: bold;

      .routemap_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }

    .map_list {
      font-size: 14px;
      color: #848a96;

      p {
        color: rgba(132, 138, 150, 1) !important;
      }

      // font-weight: bold;
      div {
        margin-top: 16px;
      }
    }

    .more {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      font-weight: bold;
      margin-top: 30px;

      .more_img {
        display: block;
        width: 12px;
        height: 16px;
        margin-left: 6px;
      }
    }
  }

  .exhibit {
    // background: var( --text-color);
    margin: 10px 0 10px;
    padding: 40px 0;

    .hold {
      font-size: 18px;
      font-weight: bold;
      color: var(--text-color);
      text-align: center;
    }

    .exhibit_li {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
      margin-top: 20px;

      .li_top {
        font-size: 34px;
        font-weight: bold;

        span {
          background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      .li_button {
        font-size: 12px;
        color: var(--text-color);
        margin-top: 6px;
      }
    }

    .specialmain {
      height: 120px;
      overflow: hidden;
      margin-top: 40px;
    }

    .special {
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .special_li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        height: 49%;
        position: relative;
        transition: transform 0.5s ease;

        .sli_yuan {
          width: 32px;
          height: 32px;
          position: absolute;
          left: 30px;
          border-radius: 50%;
        }

        .slitext {
          position: absolute;
          right: 40px;
          line-height: 10px;

          .wbnb {
            color: #5883bfff;
            font-size: 12px;
            font-weight: 500;
            font-family: 'PingFang';
          }
        }
      }
    }
  }

  .universal_box {
    overflow: hidden;
    border-radius: 20px;
    background: var(--box-bg-color);
    border: 1px solid rgba(240, 240, 240, 1);
    position: relative;
    padding: 20px;
    margin: 0 auto;
    margin-top: 20px;
    transition: transform 0.5s ease;
    width: 334px;

    .abs_img {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 91.73px;
      height: 88.91px;
    }

    // border-radius: 30px;
    .clcaption {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #c4c4c4;
      font-weight: bold;

      .pledge_img {
        display: block;
        width: 44px;
        height: 44px;
      }
    }

    .slogan {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      font-weight: bold;
      color: var(--text-color);
      margin: 10px 0;
    }

    .box_button {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 40%;
      border-radius: 16px;
      background: linear-gradient(49.6deg, #1a70fdff 0%, #26c3ffff 100%);
      font-size: 14px;
      color: var(--text-color);
      padding: 16px 0;
      margin-top: 20px;

      .box_arrow {
        display: block;
        width: 16px;
        height: 10px;
        margin-left: 8px;
      }
    }
  }

  .universal_box:nth-child(even) {
    transform: translateX(-250%);
  }

  /* 奇数 */
  .universal_box:nth-child(odd) {
    transform: translateX(250%);
  }

  .universal_box.show-center {
    transform: translateX(0);
  }

  .merchandise_bg {
    // background: url('~@/assets/img/merchandise_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .earncoins_bg {
    background: url('~@/assets/img/earncoins_bg.png') no-repeat;
    background-size: 100% 100%;

    .million {
      font-size: 14px;
      font-weight: bold;
      color: var(--text-color);
    }
  }

  .emission_bg {
    background: url('~@/assets/img/emission_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .market_bg {
    background: url('~@/assets/img/market_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .super_bg {
    background: url('~@/assets/img/super_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .partner_list {
    font-size: 14px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    // border: 1px solid #3399ff;
    padding: 26px 20px 20px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .partner {
      width: 100%;
      margin-bottom: 30px;

      .partner_title {
        font-size: 18px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: var(--text-color);
        text-align: center;
        margin-bottom: 20px;
      }

      .partner_img {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 10px;

        .img {
          width: 45%;
          height: 47px;
          border-radius: 10px;
          margin-top: 14px;
          background: var(--box-bg-color);
          display: flex;
          justify-content: center;
          align-items: center;
          transition: transform 0.5s ease;
        }

        /* 偶数 */
        .img:nth-child(even) {
          transform: translateX(-250%);
        }

        /* 奇数 */
        .img:nth-child(odd) {
          transform: translateX(250%);
        }

        .img.show-center {
          transform: translateX(0);
        }

        img {
          width: 100%;
          height: 110%;
        }

        .partner_li {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48%;
          height: 52px;
          border-radius: 16px;
          padding: 10px;
          background: var(--text-color);
          margin-bottom: 14px;
        }
      }
    }

    .reserved {
      color: var(--text-color);
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
    }

    .Icon_img {
      width: 129.89px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 40px;

      .img {
        width: 24px;
        height: 21.74px;
      }
    }
  }
}

.inDapp {
  margin: 0 auto;
  width: 336px;
  height: 44px;
  margin-bottom: 49px;
  border-radius: 10px;
  opacity: 1;
  background: linear-gradient(90deg, #1a70fdff 0%, #26c3ffff 100%);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  text-align: center;
  line-height: 44px;
}

/deep/.van-icon-success {
  color: var(--text-color) !important;
}

.snowballmain {
  padding: 20px;
}

.homeimg {
  width: 100%;
  border-radius: 15px;
}

.routerlink {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .router_li {
    width: 25%;

    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .imgbox {
      display: flex;
      align-items: center;
      justify-content: center;

      .imgbox_item {
        margin-bottom: 6px;
        width: 38px;
        height: 38px;
        background: var(--box-bg-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    span {
      color: var(--text-color);
      font-size: 13px;
      font-weight: 500;
      font-family: 'PingFang';
    }
  }
}

.notice {
  position: relative;
  margin-top: 30px;
  width: 333px;
  height: 60px;
  border-radius: 16px;
  opacity: 1;
  background: rgba(247, 247, 247, 1);

  padding: 10px;
  display: flex;
  align-items: center;

  img {
    height: 100%;
    margin-right: 10px;
  }

  span {
    color: var(--text-color);
    font-size: 14px;
    font-weight: 500;
    margin-right: 5px;
  }

  .shoyi {
    position: absolute;
    right: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background: url('~@/assets/imgsnowball/shoyiyou.png') no-repeat;
    width: 36px;
    height: 36px;
    background-size: contain;
  }
}

.d6 {
  color: #1d1d1d;
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins';
}

.jiasho {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .box {
    padding: 11px 12px;

    border-radius: 10px;
    height: 100%;
    background: #26272bff;
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .jiage {
    .j_span1 {
      color: #ced0deff;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .j_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
    }

    .j_span3 {
      color: #5fc88fff;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .j_div1 {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .shandui {
        color: var(--text-color);
        width: 41px;
        height: 21px;
        border-radius: 55px;
        opacity: 1;
        background: #808cf5;
        text-align: center;
        line-height: 21px;
        font-size: 10px;
        font-weight: 500;
        font-family: 'HarmonyOSSansSC';
      }
    }
  }

  .d_shoyi {
    position: relative;

    .s_div {
      align-self: flex-end;
      width: 41px;
      height: 21px;
      border-radius: 55px;
      position: absolute;
      bottom: 10px;
      background: #ffce57ff;
      color: #1c203aff;
      font-size: 10px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      text-align: center;
      line-height: 21px;
    }

    .s_span1 {
      color: #ffce57ff;
      font-size: 14px;
      font-weight: 700;
      font-family: 'PingFang';
    }

    .s_span2 {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 500;
      font-family: 'HarmonyOSSansSC';
      width: 60%;
      display: flex;
      justify-content: space-between;
      // display: flex;
      // justify-content: space-between;
      // width: 50%;
    }
  }
}

.echathead {
  .e_span1 {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 20px;
  }

  .e_span2 {
    color: #9395a4ff;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';

    img {
      width: 12px;
      height: 12px;
    }
  }
}

#echat {
  margin: 0 auto;
}

.zhuzao {
  width: 100%;

  border-radius: 16px;
  opacity: 1;
  position: relative;
  background: rgba(247, 247, 247, 1);

  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .zhuzao_right {

    display: flex;
    flex-direction: column;

    .hasmter_button {
      color: #222222;
      font-size: 12px;
      font-weight: 500;
      padding: 3px 7px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      background: rgba(248, 147, 31, 1);
    }
  }

  .zhuzao_left {
    display: flex;
    flex-direction: column;

    .zhuzao_left_title {
      font-size: 16px;
      font-weight: bold;

      &> :first-child {
        color: rgba(69, 135, 143, 1);
      }

      &> :last-child {
        color: var(--text-color);
      }
    }
  }

  .zhuzao_button {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 24px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    right: 16px;
    bottom: 22px;
    width: 91px;
    height: 36px;
    opacity: 1;
    border-radius: 36px;
    padding: 2px;
    background: rgba(102, 180, 174, 1);

    .down_icon {
      transform: rotate(-90deg);
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background: var(--bg-color);
      color: var(--text-color);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  img {
    width: 97px;
    height: 81px;
    margin-top: 6px;
    margin-left: 20px;
  }
}

.zichantitle {
  display: flex;
  align-items: center;
  margin-top: 30px;

  .title {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Poppins';
    margin-right: 40px;
  }

  //    /deep/.van-checkbox__icon--square{
  //     position: absolute;
  //     left: 1px;
  //     top: 5px;

  // }
  .yingcang {
    color: #999999ff;
    font-size: 12px;
    font-weight: 500;
    font-family: 'PingFang';
  }

  /deep/.van-checkbox__icon .van-checkbox__icon {
    border-color: #fff !important;
  }

  /deep/.van-checkbox__icon--checked .van-icon-success {
    color: #8b5411;
  }
}

.zichanlist {
  margin-top: 10px;
  width: 100%;
  // height: 213px;
  border-radius: 10px;
  background: var(--box-bg-color);
  border: 1px solid rgba(240, 240, 240, 1);
  padding: 17px 14px;
  padding-top: 0;

  .li_title {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    color: #999999ff;
    font-size: 12px;
    font-weight: 700;
    font-family: 'PingFang';
    border-bottom: 1px solid rgba(240, 240, 240, 1);
    padding-bottom: 12px;
  }
}

.li_con {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  div {
    color: var(--text-color);
    width: 30%;
    text-align: center;
    line-height: 40px;
  }

  .li1 {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
}


.gongaox {
  position: absolute;
  right: 21px;
}

.o_cont {
  position: relative;
  width: 335px;
  min-height: 250px;

  overflow: hidden;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px 21px;
  background: var(--line-bg-color);

  .line {
    background: var(--text-color);
    width: 100%;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .title {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 700;
    font-family: 'PingFang';
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 350px;
    overflow-y: scroll;

    div {
      margin-bottom: 10px;
      white-space: pre-wrap;

      white-space: normal;
      word-break: break-word;
    }
  }
}


.assets {
  padding-bottom: 20px;
  width: 100%;

  .a_title {
    color: #999999;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Poppins';
  }

  .a_number {
    .num {
      color: #000;
      font-size: 36px;
      font-weight: 500;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 5px;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      font-family: 'Poppins';
    }
  }

  .a_shoyi {
    display: flex;

    .num {
      color: #c75d5d;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }

    .tit {
      margin-left: 10px;
      color: #999999;
      font-size: 16px;
      font-weight: 400;
      font-family: 'Poppins';
    }
  }
}
/deep/.van-icon-success{
  color: #fff !important;
}
</style>