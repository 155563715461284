<template>
  <div class="iso">
    
    <div class="i_main"
      :style="`background: url(${bglist.image1}) no-repeat;`">
      <div class="recruit_title">
      <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
      <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
      <div></div>
    </div>
      <div class="m_title">{{ $t('lang.h47') }}</div>
      <div class="m_text">{{ $t('lang.h48') }}</div>
      <div class="buttons">
        <div class="b_b1">{{ $t('lang.swap356') }}</div>
        <div class="b_b1">{{ $t('lang.swap357') }}</div>
      </div>
    </div>
    <div class="i_sosuo">
      <div class="s_line1">
        <div class="region">
          <div v-for="( item, index) of switchlist" :key="index" @click="shiswitch(index)"
            :class="index == regionIndex ? 'special' : ''">
            <span>{{ $t(item) }}</span>
          </div>
        </div>
        <div class="l_zhi">
          <van-switch v-model="checked" size="18px" active-color="rgba(216, 232, 231, 1)" inactive-color="#fff" />
          <span>{{ $t('lang.swap360') }}</span>
        </div>

      </div>
      <div class="i_pai">
        <div class="p_li">
          <span>{{ $t('lang.swap361') }}</span>
          <div class="menu">
            {{ $t('lang.swap363') }}
            <van-icon name="play" class="m_icon" @click="remeshowfun" />
            <!-- <div class="over"
                 v-show="remenshow"></div> -->
          </div>
        </div>
        <div class="p_li">
          <span>{{ $t('lang.swap362') }}</span>
          <van-field type="number" v-model="toInput" input-align="left" :placeholder="`${$t('lang.swap364')}`"
            class="van-field" />
        </div>
      </div>
    </div>
    <div class="i_lon">
      <!-- <div class="meiyou">
        <img src="@/assets/img/zanwu.png"
             alt=""
             v-if="ColorCheckData == 'black'">
        <img src="@/assets/img/zanwu1.png"
             alt=""
             v-else>
        <span>{{ $t('lang.swap365') }}</span>
      </div> -->

      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/RAC.png" alt="">
            <img src="@/assets/img/logo/USDT.png" alt="" style="margin-left: 10px;">
          </div>
          <span>RAC-USDT  {{ $t('lang.税收激励池') }}</span>
        </div>
        <div class="lon_line" style="margin-bottom: 22px;">
          <div class="line_left">
            <span style="color: rgba(34, 34, 34, 1);">{{ $t('lang.已赚取') }}</span>
            <span style="color: rgba(0, 0, 0, 1); font-size: 24px;">{{ RAC_USDTData.profit }}</span>
          </div>
          <div class="line_right_button" @click="RAC_USDTHarvest">{{ $t('lang.领取') }}</div>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="RAC_USDTData.showApprove">
          <div class="line2_title">{{ $t('lang.质押') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{ RAC_USDTData.myNumber }}</span>
            <div class="line2_button" v-if="RAC_USDTData.allowanceToRouter" @click="approveRAC_USDT">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectRAC_USDT(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectRAC_USDT(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>

      </div>


      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/RAC.png" alt="">
          </div>
          <span>RAC {{ $t('lang.单币激励池') }}</span>
        </div>
        <div class="lon_line" style="margin-bottom: 22px;">
          <div class="line_left">
            <span style="color: rgba(34, 34, 34, 1);">{{ $t('lang.已赚取') }}</span>
            <span style="color: rgba(0, 0, 0, 1);font-size: 24px;">{{ RACData.profit }}</span>
          </div>
          <div class="line_right_button" @click="RACHarvest">{{ $t('lang.领取') }}</div>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="RACData.showApprove">
          <div class="line2_title">{{ $t('lang.质押') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{ RACData.myNumber }}</span>
            <div class="line2_button" v-if="RACData.allowanceToRouter" @click="approveRAC">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectRAC(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectRAC(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>

      </div>
      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/Hamster.png" alt="">
          </div>
          <span>Hamster {{ $t('lang.置换池') }}</span>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="HamsterData.VshowApprove">
          <div class="line2_title">{{ $t('lang.已置换') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{HamsterData.myNumber }}</span>
            <div class="line2_button" v-if="HamsterData.VallowanceToRouter" @click="approveHamster('1')">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectHamster(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectHamster(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="HamsterData.showApprove">
          <div class="line2_title">{{ $t('lang.质押中') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{HamsterData.myNumber }}</span>
            <div class="line2_button" v-if="HamsterData.allowanceToRouter" @click="approveHamster('2')">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectHamster(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectHamster(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>

      </div>
      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/Hamster.png" alt="">
          </div>
          <span>vHamster{{ $t('lang.质押池') }}</span>
        </div>
        <div class="lon_line" style="margin-bottom: 22px;">
          <div class="line_left">
            <span style="color: rgba(34, 34, 34, 1);">{{ $t('lang.已赚取') }}</span>
            <span style="color: rgba(0, 0, 0, 1);font-size: 24px;">{{ vHamsterData.profit }}</span>
          </div>
          <div class="line_right_button" @click="vHamsterHarvest">{{ $t('lang.领取') }}</div>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="vHamsterData.showApprove">
          <div class="line2_title">{{ $t('lang.质押') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{ vHamsterData.myNumber }}</span>
            <div class="line2_button" v-if="vHamsterData.allowanceToRouter" @click="approvevHamster">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectvHamster(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectvHamster(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>
        </div>

      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/RAC.png" alt="">
            <img src="@/assets/img/logo/49DAO.png" alt="" style="margin-left: 10px;">
          </div>
          <span>RAC-DAO{{ $t('lang.激励池') }}</span>
        </div>
        <div class="lon_line" style="margin-bottom: 22px;">
          <div class="line_left">
            <span style="color: rgba(34, 34, 34, 1);">{{ $t('lang.已赚取') }}</span>
            <span style="color: rgba(0, 0, 0, 1);font-size: 24px;">{{ RAC_DAOData.profit }}</span>
          </div>
          <div class="line_right_button" @click="RAC_DAOHarvest">{{ $t('lang.领取') }}</div>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="RAC_DAOData.showApprove">
          <div class="line2_title">{{ $t('lang.质押') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{ RAC_DAOData.myNumber }}</span>
            <div class="line2_button" v-if="RAC_DAOData.allowanceToRouter" @click="approveRAC_DAO">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectRAC_DAO(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectRAC_DAO(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>

      </div>
      <div class="lon_list">
        <div class="lon_title">
          <div class="title_img">
            <img src="@/assets/img/logo/Hamster.png" alt="">
            <img src="@/assets/img/logo/49DAO.png" alt="" style="margin-left: 10px;">
          </div>
          <span>Hamster-DAO{{ $t('lang.激励池') }}</span>
        </div>
        <div class="lon_line" style="margin-bottom: 22px;">
          <div class="line_left">
            <span style="color: rgba(34, 34, 34, 1);">{{ $t('lang.已赚取') }}</span>
            <span style="color: rgba(0, 0, 0, 1);font-size: 24px;">{{ Hamster_DAOData.profit }}</span>
          </div>
          <div class="line_right_button" @click="Hamster_DAOHarvest">{{ $t('lang.领取') }}</div>
        </div>
        <div class="lon_line2" style="margin-bottom: 18px;" v-loading="Hamster_DAOData.showApprove">
          <div class="line2_title">{{ $t('lang.质押') }}</div>
          <div style="width: 100%; display: flex; align-items: center; justify-content: space-between;">
            <span style="font-size: 24px;">{{ Hamster_DAOData.myNumber }}</span>
            <div class="line2_button" v-if="Hamster_DAOData.allowanceToRouter" @click="approveHamster_DAO">{{ $t('lang.授权') }}</div>
            <div v-else class="line3_butttons">
              <div class="decrease" @click="selectHamster_DAO(false)"><van-icon name="minus" /></div>
              <div class="add"  @click="selectHamster_DAO(true)"><van-icon name="plus" /></div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <van-popup v-model="MiningPopupShowRACUSDT"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmRACUSDT"
                   @getMyAddress="getBalanceRACUSDT"
                   :selectShow="selectShowRACUSDT"
                   :max="max"
                   currencyCharacter="RAC-USDT LP"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowRACDAO"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmRACDAO"
                   @getMyAddress="getBalanceRACDAO"
                   :selectShow="selectShowRACDAO"
                   :max="max"
                   currencyCharacter="RDAO"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowHamster"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmHamster"
                   @getMyAddress="getBalanceHamster"
                   :selectShow="selectShowHamster"
                   :max="max"
                   currencyCharacter="Hamster"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowRAC"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmRAC"
                   @getMyAddress="getBalanceRAC"
                   :selectShow="selectShowRAC"
                   :max="max"
                   currencyCharacter="RAC"></MiningPopup>
    </van-popup>
    <van-popup v-model="MiningPopupShowHamsterDAO"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmHamsterDAO"
                   @getMyAddress="getBalanceHamsterDAO"
                   :selectShow="selectShowHamsterDAO"
                   :max="max"
                   currencyCharacter="HDAO"></MiningPopup>
    </van-popup>

    <van-popup v-model="MiningPopupShowvHamster"
               round>
      <MiningPopup @handleConfirm="MiningPopupfirmvHamster"
                   @getMyAddress="getBalancevHamster"
                   :selectShow="selectShowvHamster"
                   :max="max"
                   currencyCharacter="vHamster"></MiningPopup>
    </van-popup>

  </div>
</template>

<script>
import {RAC_USDT_LPADDR,RAC_USDT_PLEDGE_ADDR } from './Configs/RAC_USDT/ConfigAddr.js'
import { RAC_DAO_LPADDR, RAC_DAO_PLEDGE_ADDR} from './Configs/RAC_DAO/ConfigAddr.js'
import {HAMSTER_LPADDR, HAMSTER_PLEDGE_ADDR} from './Configs/Hamster/ConfigAddr.js'
import {VHAMSTER_LPADDR, VHAMSTER_PLEDGE_ADDR} from './Configs/vHamster/ConfigAddr.js'
import {RAC_LPADDR, RAC_PLEDGE_ADDR} from './Configs/RAC/ConfigAddr.js'
import {Hamster_DAO_LPADDR, Hamster_DAO_PLEDGE_ADDR } from './Configs/Hamster_DAO/ConfigAddr.js'
import MiningPopup from './MiningPopup.vue';
import { mapState } from 'vuex';
import { getErc20Contract } from '@/utils/contractHelp';
import { useErc20Contract } from '@/utils/useContract';
import { MaxUint256 } from '@ethersproject/constants';
import RAC_USDT_api from './Configs/RAC_USDT/pledge_api.js';
import RAC_DAO_api from './Configs/RAC_DAO/pledge_api.js';
import Hamster_api from './Configs/Hamster/pledge_api.js';
import vHamster_api from './Configs/vHamster/pledge_api.js';
import RAC_api from './Configs/RAC/pledge_api.js';
import Hamster_DAO_api from './Configs/Hamster_DAO/pledge_api.js';
import { significantDigits, parseAmount, formatAmount } from '@/utils/format.js';
export default {
  name: 'iso',
  components: {
    MiningPopup
  },
  data() {
    return {
      regionIndex: 0,
      toInput: '',
      checked: false,
      remenshow: false,
      bglist: {
        image: require('@/assets/img/earnmoneybg.png'),
        image1: require('@/assets/img/earnmoneybg.png'),
      },
      switchlist: ["lang.swap358", "lang.swap359"],
      max: 0,
      MiningPopupShowRACUSDT:false,
      MiningPopupShowRACDAO :false,
      MiningPopupShowHamster :false,
      MiningPopupShowRAC :false,
      MiningPopupShowHamsterDAO :false,
      MiningPopupShowvHamster:false,
      selectShowRACUSDT: false,
      selectShowRACDAO: false,
      selectShowHamster: false,
      selectShowvHamster: false,
      selectShowRAC: false,
      selectShowHamsterDAO: false,
      RAC_USDTData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      RAC_DAOData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      HamsterData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        VshowApprove:false,
        allowanceToRouter: false,
        VallowanceToRouter:false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      RACData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      Hamster_DAOData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
      vHamsterData: {
        confirmBtnShow: false, //是否授权
        showApprove: false, //是否授权
        allowanceToRouter: false,
        myNumber: 0,
        profit: 0,
        balance: 0, //
        max: 0,
        allReward: 0,
        todayReward: 0,
        userTvl: 0,
        maxTVL: 0,
        OtherTVL: 0,
        power: 0,
        pledge_num: 0,
      },
    }
  },
  computed: {
    ...mapState(['account', 'lang', 'ColorCheckData']),
  },
  created() {
     this.init()
  },
  watch: {
    account () {
      //获取账号
      this.init()
    },
  },
  
  methods: {
    toprouter() {
      this.$router.go(-1);
    },
    init(){
      if (this.account) {
      this.handleApproveBtnShow();
      this.getBalanceOf();
      this.getDeposit();
      this.getReward();
    }
    },
    shiswitch(index) {
      this.regionIndex = index;
    },
    remeshowfun() {
      this.remenshow = !this.remenshow
    },
    MiningPopupfirmRACUSDT () {
      this.MiningPopupShowRACUSDT = !this.MiningPopupShowRACUSDT;
    },
    MiningPopupfirmRACDAO () {
      this.MiningPopupShowRACDAO = !this.MiningPopupShowRACDAO;
    },
    MiningPopupfirmHamster () {
      this.MiningPopupShowHamster = !this.MiningPopupShowHamster;
    },
    MiningPopupfirmvHamster () {
      this.MiningPopupShowvHamster = !this.MiningPopupShowvHamster;
    },
    MiningPopupfirmRAC () {
      this.MiningPopupShowRAC = !this.MiningPopupShowRAC;
    },
    MiningPopupfirmHamsterDAO () {
      this.MiningPopupShowHamsterDAO = !this.MiningPopupShowHamsterDAO;
    },
    getBalanceRACUSDT (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowRACUSDT = false;
      if (this.selectShowRACUSDT) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'RAC-USDT LP',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_USDT_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'RAC-USDT LP',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_USDT_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalancevHamster (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowvHamster = false;
      if (this.selectShowvHamster) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'vHamster',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        vHamster_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'vHamster',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        vHamster_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceRACDAO (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowRACDAO = false;
      if (this.selectShowRACDAO) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'RDAO',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_DAO_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'RDAO',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_DAO_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceHamster (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowHamster = false;
      if (this.selectShowHamster) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'Hamster',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        Hamster_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'Hamster',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        Hamster_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceRAC (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowRAC = false;
      if (this.selectShowRAC) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'RAC',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'RAC',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        RAC_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    getBalanceHamsterDAO (res) {
      let amount = parseAmount(res, 18);
      this.MiningPopupShowHamsterDAO = false;
      if (this.selectShowHamsterDAO) {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.质押') + 'HDAO',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        Hamster_DAO_api.stake(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      } else {
        const transactionsDetail = {
          title: '',
          message: this.$t('lang.赎回') + 'HDAO',
          addedTime: new Date().getTime(),
          from: this.account,
        };
        Hamster_DAO_api.withdraw(this.account, amount, transactionsDetail).then(res => {
          this.getDeposit();
          this.getBalanceOf();
        });
      }
    },
    async handleApproveBtnShow() {
      //获取当前是否授权
      const RAC_USDTContract = getErc20Contract(RAC_USDT_LPADDR);
      const RAC_USDTAppBalance = await RAC_USDTContract.methods.allowance(this.account, RAC_USDT_PLEDGE_ADDR).call();
      if (RAC_USDTAppBalance == 0) {
        this.RAC_USDTData.allowanceToRouter = true;
      } else {
        this.RAC_USDTData.allowanceToRouter = false;
      }

      const RAC_DAOContract = getErc20Contract(RAC_DAO_LPADDR);
      const RAC_DAOAppBalance = await RAC_DAOContract.methods.allowance(this.account, RAC_DAO_PLEDGE_ADDR).call();
      if (RAC_DAOAppBalance == 0) {
        this.RAC_DAOData.allowanceToRouter = true;
      } else {
        this.RAC_DAOData.allowanceToRouter = false;
      }

      const HamsterContract = getErc20Contract(HAMSTER_LPADDR);
      const HamsterAppBalance = await HamsterContract.methods.allowance(this.account, HAMSTER_PLEDGE_ADDR).call();
      if (HamsterAppBalance == 0) {
        this.HamsterData.allowanceToRouter = true;
      } else {
        this.HamsterData.allowanceToRouter = false;
      }

      const VHamsterContract = getErc20Contract(VHAMSTER_LPADDR);
      const VHamsterAppBalance = await VHamsterContract.methods.allowance(this.account, HAMSTER_PLEDGE_ADDR).call();
      if (VHamsterAppBalance == 0) {
        this.HamsterData.VallowanceToRouter = true;
      } else {
        this.HamsterData.VallowanceToRouter = false;
      }

      const vHamsterAppBalance = await VHamsterContract.methods.allowance(this.account, VHAMSTER_PLEDGE_ADDR).call();
      if (vHamsterAppBalance == 0) {
        this.vHamsterData.allowanceToRouter = true;
      } else {
        this.vHamsterData.allowanceToRouter = false;
      }

      const RACContract = getErc20Contract(RAC_LPADDR);
      const RACAppBalance = await RACContract.methods.allowance(this.account, RAC_PLEDGE_ADDR).call();
      if (RACAppBalance == 0) {
        this.RACData.allowanceToRouter = true;
      } else {
        this.RACData.allowanceToRouter = false;
      }

      const HamsterDAOContract = getErc20Contract(Hamster_DAO_LPADDR);
      const HamsterDAOAppBalance = await HamsterDAOContract.methods.allowance(this.account, Hamster_DAO_PLEDGE_ADDR).call();
      if (HamsterDAOAppBalance == 0) {

        this.Hamster_DAOData.allowanceToRouter = true;
      } else {
        this.Hamster_DAOData.allowanceToRouter = false;
      }
    },

    async getBalanceOf () {

      const erc20ContractRAC_USDT = getErc20Contract(RAC_USDT_LPADDR);
        const balanceRAC_USDT = await erc20ContractRAC_USDT.methods.balanceOf(this.account).call();
        this.RAC_USDTData.balance = formatAmount(balanceRAC_USDT, 18);

        const erc20ContractRAC_DAO = getErc20Contract(RAC_DAO_LPADDR);
        const balanceRAC_DAO = await erc20ContractRAC_DAO.methods.balanceOf(this.account).call();
        this.RAC_DAOData.balance = formatAmount(balanceRAC_DAO, 18);

        const erc20ContractHamster = getErc20Contract(HAMSTER_LPADDR);
        const balanceHamster = await erc20ContractHamster.methods.balanceOf(this.account).call();
        this.HamsterData.balance = formatAmount(balanceHamster, 18);

        const erc20ContractvHamster = getErc20Contract(VHAMSTER_LPADDR);
        const balancevHamster = await erc20ContractvHamster.methods.balanceOf(this.account).call();
        this.vHamsterData.balance = formatAmount(balancevHamster, 18);

        const erc20ContractRAC = getErc20Contract(RAC_LPADDR); 
        const balanceRAC = await erc20ContractRAC.methods.balanceOf(this.account).call();
        this.RACData.balance = formatAmount(balanceRAC, 18);

        const erc20ContractHamsterDAO = getErc20Contract(Hamster_DAO_LPADDR);
        const balanceHamsterDAO = await erc20ContractHamsterDAO.methods.balanceOf(this.account).call();
        this.Hamster_DAOData.balance = formatAmount(balanceHamsterDAO, 18);
    },

    getDeposit () {
      RAC_USDT_api.balance(this.account).then(res => {
        this.RAC_USDTData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      RAC_DAO_api.balance(this.account).then(res => {
        this.RAC_DAOData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      vHamster_api.balance(this.account).then(res => {
        this.vHamsterData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      Hamster_api.balance(this.account).then(res => {
        this.HamsterData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      RAC_api.balance(this.account).then(res => {
        this.RACData.myNumber = (res * 1) / Math.pow(10, 18);
      });

      Hamster_DAO_api.balance(this.account).then(res => {
        this.Hamster_DAOData.myNumber = (res * 1) / Math.pow(10, 18);
      });
    },

    getReward (){
      RAC_USDT_api.earned(this.account).then(res => {
        this.RAC_USDTData.profit = res / Math.pow(10, 18);
      });

      RAC_DAO_api.earned(this.account).then(res => {
        this.RAC_DAOData.profit = res / Math.pow(10, 18);
      });

      vHamster_api.earned(this.account).then(res => {
        this.vHamsterData.profit = res / Math.pow(10, 18);
      });

      // Hamster_api.earned(this.account).then(res => {
      //   this.HamsterData.profit = res / Math.pow(10, 18);
      // });

      RAC_api.earned(this.account).then(res => {
        this.RACData.profit = res / Math.pow(10, 18);
      });

      Hamster_DAO_api.earned(this.account).then(res => {
        this.Hamster_DAOData.profit = res / Math.pow(10, 18);
      });
    },
    RAC_USDTHarvest() {
      if (this.RAC_USDTData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      RAC_USDT_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },

    vHamsterHarvest() {
      if (this.vHamsterData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      vHamster_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },

    RAC_DAOHarvest() {
      if (this.RAC_DAOData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      RAC_DAO_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },

    RACHarvest() {
      if (this.RACData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      RAC_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    Hamster_DAOHarvest() {
      if (this.Hamster_DAOData.profit == 0) {
        this.$toast(this.$t('lang.swap162'));
        return;
      }
      const transactionsDetail = {
        title: '',
        message: `${this.$t('lang.swap66')} `,
        addedTime: new Date().getTime(),
        from: this.account,
      };

      Hamster_DAO_api.claimReward(this.account, transactionsDetail).then(res => {
        this.getReward();
      });
    },
    selectRAC_USDT(state) {
      this.selectShowRACUSDT = state;
      if (state) {
        this.max = this.RAC_USDTData.balance;
      } else {
        this.max = this.RAC_USDTData.myNumber;
      }
      this.MiningPopupfirmRACUSDT();
    },
    selectvHamster(state) {
      this.selectShowvHamster = state;
      if (state) {
        this.max = this.vHamsterData.balance;
      } else {
        this.max = this.vHamsterData.myNumber;
      }
      this.MiningPopupfirmvHamster();
    },
    selectRAC_DAO(state) {
      this.selectShowRACDAO = state;
      if (state) {
        this.max = this.RAC_DAOData.balance;
      } else {
        this.max = this.RAC_DAOData.myNumber;
      }
      this.MiningPopupfirmRACDAO();
    },
    selectHamster(state) {
      this.selectShowHamster = state;
      if (state) {
        this.max = this.HamsterData.balance;
      } else {
        this.max = this.HamsterData.myNumber;
      }
      this.MiningPopupfirmHamster();
    },
    selectRAC(state) {
      this.selectShowRAC = state;
      if (state) {
        this.max = this.RACData.balance;
      } else {
        this.max = this.RACData.myNumber;
      }
      this.MiningPopupfirmRAC();
    },
    selectHamster_DAO(state) {
      this.selectShowHamsterDAO = state;
      if (state) {
        this.max = this.Hamster_DAOData.balance;
      } else {
        this.max = this.Hamster_DAOData.myNumber;
      }
      this.MiningPopupfirmHamsterDAO();
    },
    async approveRAC_USDT() {
      this.RAC_USDTData.showApprove = true;
      const erc20Contract = useErc20Contract(RAC_USDT_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(RAC_USDT_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(RAC_USDT_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.RAC_USDTData.showApprove = false;
          this.RAC_USDTData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.RAC_USDTData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approvevHamster() {
      this.vHamsterData.showApprove = true;
      const erc20Contract = useErc20Contract(VHAMSTER_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(VHAMSTER_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(VHAMSTER_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.vHamsterData.showApprove = false;
          this.vHamsterData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.vHamsterData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveRAC_DAO() {
      this.RAC_DAOData.showApprove = true;
      const erc20Contract = useErc20Contract(RAC_DAO_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(RAC_DAO_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(RAC_DAO_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.RAC_DAOData.showApprove = false;
          this.RAC_DAOData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.RAC_DAOData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveHamster(type) {
      type == '1' ?  this.HamsterData.VshowApprove = true : this.HamsterData.showApprove = true;

      const erc20Contract = useErc20Contract(type == '1'? VHAMSTER_LPADDR : HAMSTER_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(HAMSTER_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(HAMSTER_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          type == '1' ?  this.HamsterData.VshowApprove = false : this.HamsterData.showApprove = false;
          type == '1' ?  this.HamsterData.VallowanceToRouter = false : this.HamsterData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          type == '1' ?  this.HamsterData.VshowApprove = false : this.HamsterData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveRAC() {
      this.RACData.showApprove = true;
      const erc20Contract = useErc20Contract(RAC_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(RAC_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(RAC_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.RACData.showApprove = false;
          this.RACData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.RACData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
    async approveHamster_DAO() {
      this.Hamster_DAOData.showApprove = true;
      const erc20Contract = useErc20Contract(Hamster_DAO_LPADDR);
      const amo = MaxUint256.toString();
      const gas = await erc20Contract.methods.approve(Hamster_DAO_PLEDGE_ADDR, amo).estimateGas({ from: this.account })
      erc20Contract.methods
        .approve(Hamster_DAO_PLEDGE_ADDR, amo)
        .send({
          from: this.account,
          gas,
          gasPrice: parseInt(this.gasPrice)
        })
        .on('confirmation', res => {
          this.Hamster_DAOData.showApprove = false;
          this.Hamster_DAOData.allowanceToRouter = false;
        })
        .on('error', (error, receipt) => {
          this.Hamster_DAOData.showApprove = false;
          this.$toast(this.$t('lang.swap74'));
        });
    },
  },
}
</script>

<style lang="less" scoped>
.iso {
  background: var(--bg-color);
  padding-top: 50px;
}
.recruit_title {
  color: var(rgba(255, 255, 255, 1));
  padding: 0px 20px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.i_main {
  text-align: center;
  position: relative;
  width: 100%;
  height: 500px;
  // background: url(~@/assets/img/earnmoneybg.png)no-repeat;
  background-size: contain !important;
  background-position: 0 90px !important;
  // background-size: 130% 243px !important;
  // background-position: -60px 150px !important;
  padding: 20px;
 

  .m_title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 32.82px;
    color: var(--text-color);
    text-align: center;
    margin-top: 20px;
  }

  .m_text {
    margin-top: 7px;
    text-align: center;
    color: rgba(158, 156, 163, 1);
    font-size: 16px;
    font-weight: 500;
    font-family: 'HarmonyOSSansSC';
  }

  .buttons {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 40px;

    .b_b1 {
      border-radius: 16px;

      font-size: 16px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 18.75px;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
      width: 160px;
      height: 48px;
      opacity: 1;
      border-radius: 72px;
      background: rgba(102, 180, 174, 1);
    }
  }
}

.i_sosuo {
  width: 100%;
  padding: 21px;

  .s_line1 {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .region {
      padding: 3px;
      width: 130px;
      height: 40px;
      border-radius: 19px;
      border: 1px solid rgba(180, 135, 255, 0.71);
      display: flex;
      line-height: 38px;
      font-size: 12px;
      font-weight: 700;
      font-family: 'PingFang';
      color: rgba(158, 156, 163, 1);
      // margin: 20px 0;
      margin-bottom: 20px;

      div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        // width: 192rpx;
        padding: 16px 0;
      }

      .special {
        // width: 50%;
        color: #fff;
        background: rgba(102, 180, 174, 1);
        // box-shadow: 0px 1px 0px 0px rgba(1, 17, 29, 0.73);
        border-radius: 34px;
      }
    }

    .l_zhi {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;

      span {
        opacity: 0.6;
        color: var(--text-color);
        font-size: 14px;
        font-weight: 700;
        font-family: 'PingFang';
        margin-left: 5px;
      }

      /deep/.van-switch__node {
        background: var(--text-color);
      }
    }
  }

  .i_pai {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .p_li {
      height: 100%;
      color: var(--text-color);

      span {
        opacity: 0.6;
        margin-bottom: 20px;
      }
    }
  }
}

.menu {
  position: relative;
  margin-top: 20px;
  width: 137px;
  height: 50px;
  border-radius: 61px;
  opacity: 1;
  background: var(--line-bg-color);
  padding-left: 14px;
  line-height: 50px;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';

  .m_icon {
    transform: rotate(90deg);
    color: var(--text-color);
    position: absolute;
    right: 20px;
    top: 15px;
  }

  .over {
    width: 100%;
    height: 100px;
    position: absolute;
    background: var(--text-color);
    bottom: -110px;
    z-index: 9999;
    left: 0;
    border-radius: 20px;
  }
}

.van-field {
  width: 178px;
  height: 50px;
  border-radius: 61px;
  color: #bababaff;
  font-size: 16px;
  font-weight: 700;
  font-family: 'PingFang';
  background: var(--line-bg-color);
  margin-top: 20px;
  padding: 14px 14px !important;

  /deep/ .van-field__control {
    color: #000;
  }
}

.i_lon {
  width: 100%;
  min-height: 361px;
  border-radius: 30px 30px 0 0;
  margin-top: 20px;
  background: var(--box-bg-color);
  position: relative;
  padding: 28px 20px;
  padding-bottom: 100px;

  .lon_list {
    margin-bottom: 20px;
    padding: 19px 0px;
    width: 100%;

    opacity: 1;

    border-bottom: 6px solid rgba(247, 252, 250, 1);

    .lon_title {
      margin-bottom: 22px;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgba(0, 0, 0, 1);

      .title_img {
        margin-right: 5px;

        img {
          width: 32px;
          height: 32px;
        }
      }
    }

    .lon_line {
      padding: 16px 17px;
      width: 100%;
      height: 86px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(248, 247, 247, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line_left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
      }

      .line_right_button {
        width: 76px;
        height: 34px;
        opacity: 1;
        border-radius: 72px;
        background: rgba(102, 180, 174, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: #fff;
      }
    }

    .lon_line2 {
      width: 100%;
      height: 109px;
      opacity: 1;
      border-radius: 16px;
      background: rgba(248, 247, 247, 1);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 18px;

      .line2_title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(0, 0, 0, 1);
      }

      .line2_button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 129px;
        height: 41px;
        opacity: 1;
        border-radius: 8px;
        background: rgba(102, 180, 174, 1);
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0px;
        line-height: 24px;
        color: #fff;
      }

      .line3_butttons {
        width: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &>div {
          width: 34px;
          height: 34px;
          opacity: 1;
          border-radius: 72px;
          background: rgba(102, 180, 174, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          line-height: 34px;
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    .lon_line3 {
      margin-bottom: 7px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .line3_left {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }

      .line3_right {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 18px;
        color: rgba(153, 153, 153, 1);
      }
    }
  }

  .meiyou {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 89px;
      height: 158px;

      margin-bottom: 18px;
    }

    span {
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 19.6px;
      color: rgba(168, 168, 168, 1);
    }
  }
}
</style>