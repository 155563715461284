<template>
    <div class="MintPool">
        <div class="recruit_title">
            <div style="transform: rotate(90deg); color: #000" @click="toprouter"><van-icon name="down" /></div>
            <div style="font-size: 18px; font-weight: bold; margin-left: 20px"></div>
            <div></div>
        </div>
        <div class="MintPool_box">
            <div class="MintPool_box_top">
                <span>0.00</span>
                <img src="@/assets/img/MintPool_img1.png" alt="">
            </div>
            <div class="MintPool_box_bottom">
                <div class="MintPool_box_bottom_left">
                    <div>
                        <span> {{ $t('lang.算力奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">0</span>
                    </div>
                    <div>
                        <span> {{ $t('lang.社区奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">0</span>
                    </div>
                    <div>
                        <span>{{ $t('lang.分享奖励') }}</span>
                        <span style="color: #FFFFFF; font-size: 12px;">0</span>
                    </div>
                </div>
                <button @click="GoMintBill" class="MintPool_box_bottom_button">
                    {{ $t('lang.账单') }}
                </button>
            </div>
        </div>
        <div class="MintPool_box2">
            <div class="MintPool_box2_line">
                <img src="@/assets/img/MintPool_box2_img1.png" alt="">
                <span>{{ $t('lang.全网社区算力') }}：0</span>
            </div>
            <div class="MintPool_box2_line">
                <img src="@/assets/img/MintPool_box2_img2.png" alt="">
                <span>{{ $t('lang.全网质押算力') }}：0</span>
            </div>
        </div>
        <div class="lookboxs">
            <div class="lookbox">
                <div class="abs_look">{{ $t('lang.o105') }}</div>
                <div class="box">
                    <div class="box_num">0</div>
                    <img src="@/assets/img/lookbox_icon1.png" alt="" />
                    <div class="box_title">{{ $t('lang.个人算力') }}</div>
                </div>
            </div>
            <div class="lookbox" style="border: 1px solid #75CF5F;">
                <div class="abs_look" style="background: #75CF5F;">{{ $t('lang.o105')
                    }}</div>
                <div class="box" style="background: linear-gradient(0deg, #75CF5F 0%, #FFFFFF 100%)">
                    <div class="box_num">0</div>
                    <img src="@/assets/img/lookbox_icon2.png" alt="" />
                    <div class="box_title">{{ $t('lang.最大社区') }}</div>
                </div>
            </div>
            <div class="lookbox" style="border: 1px solid #66B4AE;">
                <div class="abs_look" style="background:#66B4AE;">{{ $t('lang.o105')
                    }}</div>
                <div class="box" style="background: linear-gradient(0deg, #66B4AE 0%, #FFFFFF 100%)">
                    <div class="box_num">0</div>
                    <img src="@/assets/img/lookbox_icon3.png" alt="" />
                    <div class="box_title">{{ $t('lang.其他社区') }}</div>
                </div>
            </div>
        </div>
        <Luckylist />
        <DTlist />
        <sharelist />
        <div class="MintPoolist">
            <div class="MintPoolist_box">
                <div class="MintPoolist_box_top">
                    <div class="MintPoolist_box_top_logo">
                        <img src="@/assets/img/logo/RAC.png" alt="">
                        <img style="margin-left: -10px;" src="@/assets/img/logo/USDT.png" alt="">
                    </div>
                    <span>RAC-USDT</span>
                </div>
                <div class="MintPoolist_box_box">
                    <div class="MintPoolist_box_box_title">{{ $t('lang.挖矿中') }}</div>
                    <div class="MintPoolist_box_box_num">
                        <div class="MintPoolist_box_box_num_left">
                            <span>Power≈0</span>
                            <span>TVL ≈0</span>
                        </div>
                        <div @click="addMintPoolShowOpen" class="MintPoolist_box_box_num_right">
                            <van-icon name="plus" />
                        </div>
                    </div>

                </div>
            </div>
            <div class="MintPoolist_box">
                <div class="MintPoolist_box_top">
                    <div class="MintPoolist_box_top_logo">
                        <img src="@/assets/img/logo/RAC.png" alt="">
                        <img style="margin-left: -10px;" src="@/assets/img/logo/Hamster.png" alt="">
                    </div>
                    <span>RAC-Hamster</span>
                </div>
                <div class="MintPoolist_box_box">
                    <div class="MintPoolist_box_box_title">{{ $t('lang.挖矿中') }}</div>
                    <div class="MintPoolist_box_box_num">
                        <div class="MintPoolist_box_box_num_left">
                            <span>Power≈0</span>
                            <span>TVL ≈0</span>
                        </div>
                        <div @click="addMintPoolShowOpen" class="MintPoolist_box_box_num_right">
                            <van-icon name="plus" />
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="MintPoolistpledge">
            <div class="MintPoolistpledge_titles">
                <div @click="activeMintPoolistpledgeFunc(0)" :class="activeMintPoolistpledge == 0 ? 'active' : ''">{{ $t('lang.质押中') }}
                </div>
                <div @click="activeMintPoolistpledgeFunc(1)" :class="activeMintPoolistpledge == 1 ? 'active' : ''">{{ $t('lang.已赎回') }}
                </div>
            </div>
            <div class="MintPoolistpledge_box">
                <div class="MintPoolistpledge_box_Nolength">
                    <img src="@/assets/img/MintPoolistpledge_box_Nolength.png" alt="">
                    <span>{{ $t('lang.暂无数据') }}~</span>
                </div>
            </div>
        </div>
        <van-popup v-model="addMintPoolShow" position="bottom" round closeable>
            <div class="addMintPoolShow">
                <div class="addMintPoolShow_title">{{ $t('lang.账户余额') }}：</div>
                <div class="addMintPoolShow_input">0</div>
                <div class="addMintPoolShow_title" style="margin-top: 20px;">{{ $t('lang.质押数量') }}：</div>
                <div class="addMintPoolShow_input">
                    <input type="number">
                    <div class="addMintPoolShow_input_all">{{ $t('lang.全部') }}</div>
                </div>
                <div class="addMintPoolShow_tips">{{ $t('lang.单次新增数量为1的倍数') }}</div>
                <button class="addMintPoolShow_btn" @click="enteraddMintPoolShow">{{ $t('lang.确定') }}</button>
            </div>
        </van-popup>
    </div>
</template>

<script>
import Luckylist from './components/Luckylist.vue';
import DTlist from './components/DTlist.vue';
import sharelist from './components/sharelist.vue';
export default {
    data() {
        return {
            addMintPoolShow: false,
            activeMintPoolistpledge: 0
        }
    },
    components: {
        Luckylist, DTlist, sharelist
    },
    mounted() {

    },
    methods: {
        toprouter(){
             this.$router.go(-1);
        },
        GoMintBill() {
            this.$router.push({
                name: 'MintBill'
            })
        },
        addMintPoolShowOpen() {
            this.addMintPoolShow = true;
        },
        enteraddMintPoolShow() {
            this.addMintPoolShow = false;
        },
        activeMintPoolistpledgeFunc(index) {
            this.activeMintPoolistpledge = index;
        }
    },
}

</script>
<style scoped lang='less'>
.MintPool {
    padding: 52px 20px;

    .recruit_title {
        color: var(rgba(255, 255, 255, 1));
        padding: 20px 0px;

        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.addMintPoolShow {
    padding: 18px 20px 34px 20px;

    .addMintPoolShow_title {
        font-size: 16px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 12px;
    }

    .addMintPoolShow_input {
        border-radius: 7px;
        width: 100%;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #D9D9D9;

        .addMintPoolShow_input_all {
            font-size: 14px;
            color: #66B4AE;
            border-left: 1px solid #E6E6E6;
            padding-left: 23px;
        }

        input {
            border: none;
        }
    }

    .addMintPoolShow_tips {
        margin-top: 8px;
        font-size: 12px;
        color: #999999;
    }

    .addMintPoolShow_btn {
        margin-top: 21px;
        height: 48px;
        width: 100%;
        background: #66B4AE;
        border-radius: 16px;
        font-size: 16px;
        color: #FFFFFF;
        font-weight: bold;
        border: none;
    }
}

.MintPoolistpledge {
    .MintPoolistpledge_box {
        .MintPoolistpledge_box_Nolength {
            padding: 80px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #999999;
            font-size: 12px;

            img {
                width: 48px;
                height: 52px;
                margin-bottom: 10px;
            }
        }
    }

    .MintPoolistpledge_titles {
        display: flex;
        align-items: center;
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        gap: 38px;

        .active {
            color: #000000;
            font-size: 16px;
            position: relative;
        }

        .active::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -5px;
            transform: translateX(-50%);
            width: 27px;
            height: 2px;
            background: #66B4AE;
        }
    }
}

.MintPoolist {
    .MintPoolist_box {
        margin-top: 26px;
        padding: 20px 0 26px 0;
        border-top: 6px solid #F7F7F7;

        .MintPoolist_box_box {
            margin-top: 16px;
            padding: 23px 18px;
            background: #F7F7F7;
            border-radius: 16px;

            .MintPoolist_box_box_title {
                color: #999999;
                font-size: 14px;
                font-weight: 500;
            }

            .MintPoolist_box_box_num {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .MintPoolist_box_box_num_left {
                    display: flex;
                    gap: 35px;
                    color: #000000;
                    font-size: 16px;
                    font-weight: bold;
                }

                .MintPoolist_box_box_num_right {
                    width: 32px;
                    height: 32px;
                    border-radius: 19px;
                    background: #66B4AE;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: #FFFFFF;
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .MintPoolist_box_top {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: #000000;
            font-weight: bold;

            .MintPoolist_box_top_logo {
                margin-right: 11px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}

.MintPool_box {
    padding: 7px 14px 18px 14px;
    width: 100%;
    border-radius: 16px;
    background: linear-gradient(138.26deg, rgba(102, 180, 174, 1) 0%, rgba(102, 180, 174, 1) 100%);

    .MintPool_box_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 24px;
        color: #FFFFFF;
        font-weight: bold;

        img {
            width: 100px;
            height: 75px;
        }
    }

    .MintPool_box_bottom {
        margin-top: 12px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .MintPool_box_bottom_button {
            padding: 0 18px;
            border: none;
            height: 34px;
            border-radius: 6px;
            font-size: 14px;
            color: #66B4AE;
            font-weight: bold;
        }

        .MintPool_box_bottom_left {
            margin-right: 49px;
            flex: 1;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &>div {
                display: flex;
                flex-direction: column;
                text-align: left;
                font-size: 10px;
                gap: 4px;
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }
}

.MintPool_box2 {
    margin-top: 6px;

    .MintPool_box2_line {
        margin-top: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        background: #F7F7F7;
        padding: 12px 0;
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: bold;

        img {
            width: 20px;
            height: 20px;
            margin-right: 6px;
        }
    }
}

.lookboxs {
    display: flex;
    width: 335px;
    margin: 0 auto;
    margin-top: 24px;
    justify-content: space-between;
    align-items: center;

    .lookbox {
        padding-top: 6px;
        position: relative;
        background-clip: padding-box;
        width: 103.77px;
        padding-bottom: 6px;
        border: 1px solid #34F7F4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;

        .abs_look {
            position: absolute;
            right: 6px;
            top: 6px;
            padding: 3px;
            border-radius: 4px;
            background: #34F7F4;
            color: rgba(0, 0, 0, 1);
            font-size: 10px;
        }

        .box {
            width: 88.09px;
            padding: 16px 0 9px 0;
            display: flex;
            border-radius: 8px;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            background: linear-gradient(0deg, #34F7F4 0%, #FFFFFF 100%);
        }

        .box_num {
            font-size: 18px;
            color: rgba(0, 0, 0, 1);
        }

        .box_title {
            font-size: 12px;
            color: rgba(0, 0, 0, 1);
        }

        img {
            margin-top: 10px;
            margin-bottom: 14px;
            width: 26px;
            height: 26px;
        }
    }
}
</style>