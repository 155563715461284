<template>
    <div class="Luckylist">
        <div class="Luckylist_line1">
            <img src="@/assets/img/lucklist_icon1.png" alt="">
            <span>{{ $t('lang.幸运榜单') }}</span>
        </div>
        <div class="Luckylist_line2">
            <div class="Luckylist_line2_left">
                <img src="@/assets/img/lucklist_icon2.png" alt="">
                <span>{{ $t('lang.当前奖池金额') }}：0 </span>
            </div>
            <img class="Luckylist_line2_right" src="@/assets/img/lucklist_img1.png" alt="">
        </div>
        <div class="Luckylist_line3">
            <div class="Luckylist_line3_box">00</div>
            <div class="Luckylist_line3_quote">:</div>
            <div class="Luckylist_line3_box">00</div>
            <div class="Luckylist_line3_quote">:</div>
            <div class="Luckylist_line3_box">00</div>
        </div>
        <div class="Luckylist_line4">
            <img src="@/assets/img/lucklist_icon3.png" alt="">
            <span>{{ $t('lang.开奖倒计时') }}</span>
        </div>
        <div class="Luckylist_line5">
            <div class="Luckylist_line5_NoLength">
                <img src="@/assets/img/lucklist_bill_img1.png" alt="">
                <span>{{ $t('lang.暂无数据') }}~</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    components: {

    },
    mounted() {

    },
    methods: {

    },
}

</script>
<style scoped lang='less'>
.Luckylist {
    margin-top: 18px;
    background: #F7F7F7;
    border-radius: 10px;
    padding: 12px 13px;

    .Luckylist_line5 {
        .Luckylist_line5_NoLength {
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #999999;
            font-size: 12px;
            img {
                margin-bottom: 13px;
                width: 47.01px;
                height: 55px;
            }
        }
    }

    .Luckylist_line4 {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #66B4AE;
        font-size: 14px;
        gap: 5px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .Luckylist_line3 {
        display: flex;
        align-items: center;
        justify-content: center;

        .Luckylist_line3_quote {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin: 0 11px;
        }

        .Luckylist_line3_box {
            width: 35px;
            height: 40px;
            opacity: 1;
            border-radius: 6px;
            background: #66B4AE;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #FFFFFF;
            font-weight: bold;
        }
    }

    .Luckylist_line2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Luckylist_line2_left {
            margin-top: 20px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 14px;
            font-weight: bold;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        .Luckylist_line2_right {
            width: 122px;
            height: 122px;
        }
    }

    .Luckylist_line1 {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        gap: 7px;

        img {
            width: 24px;
            height: 24px;
        }
    }

}
</style>